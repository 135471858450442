//###########################################################################
// Become a stockist
//###########################################################################

import Vue from 'vue'
Vue.config.productionTip = false
import { scrollTo } from '../utilities/_scrollTo'
import { VueRecaptcha } from 'vue-recaptcha'
import { postToController } from '../utilities/_postToController'

if (document.querySelector('#form-become-stockist')) {
    new Vue({
        el: '#form-become-stockist',
        delimiters: ['${', '}'],
        data: {
            errors: [],
            subject: '',
            fromName: null,
            fromEmail: null,
            toEmail: null,
            phoneNumber: null,
            companyName: null,
            companyAddress: null,
            companyPostcode: null,
            privacyConfirmation: false,
            message: null,
            website: '',
            recaptchaVerified: false
        },
        components: { VueRecaptcha },
        methods: {
            send: function (e) {
                e.preventDefault()

                this.errors = [];

                if (!this.fromName) {
                    this.errors.push("Name required.")
                }

                if (!this.fromEmail) {
                    this.errors.push('Email required.')
                }

                if (this.fromEmail && !this.validateEmail(this.fromEmail)) {
                    this.errors.push('Email address is incorrect.')
                }

                if (!this.phoneNumber) {
                    this.errors.push('Phone number is required.')
                }

                if (!this.companyName) {
                    this.errors.push('Company name is required.')
                }

                if (!this.companyAddress) {
                    this.errors.push('Company address is required.')
                }

                if (!this.companyPostcode) {
                    this.errors.push('Company postcode is required.')
                }

                if (!this.privacyConfirmation) {
                    this.errors.push('Please agree to our privacy policy.')
                }

                if (!this.recaptchaVerified) {
                    this.errors.push('Please complete the Google Recaptcha.')
                }

                if (this.website !== '') {
                    this.errors.push('An unknown error occured.')
                }

                if (this.errors.length > 0) {
                    // If there's errors, scroll to them and offset by header (because we're scrolling up)
                    const headerHeight = document.querySelector('#header').clientHeight
                    scrollTo(document.querySelector('.form-container'), -headerHeight)
                }

                if (!this.errors.length) {
                    let bodyFormData = new FormData()
                    bodyFormData.append('action', 'contact-form/send')
                    bodyFormData.append('subject', this.subject)
                    bodyFormData.append('website', this.website)
                    bodyFormData.append('fromName', this.fromName)
                    bodyFormData.append('fromEmail', this.fromEmail)
                    bodyFormData.append('toEmail', this.toEmail)
                    bodyFormData.append('message[Phone number]', this.phoneNumber)
                    bodyFormData.append('message[Company name]', this.companyName)
                    bodyFormData.append('message[Company address]', this.companyAddress)
                    bodyFormData.append('message[Company postcode]', this.companyPostcode)
                    bodyFormData.append('message[Message]', this.message)

                    postToController(
                        '', bodyFormData,
                        (response) => {

                            if (window.ga) {
                                window.ga('send', 'event', 'Form', 'Submission', 'Become a Stockist Page');
                            }

                            if (window.gtag) {
                                gtag('event', 'Submission', {
                                    'event_category': 'Form',
                                    'event_label': 'Become a Stockist Page',
                                    'value': 0
                                });
                            }

                            // Handle success
                            document.querySelector('.form-hold').classList.add("form--complete")

                            // Scroll to form success message
                            const headerHeight = document.querySelector('#header').clientHeight
                            scrollTo(document.querySelector('.form-complete'), -headerHeight)
                        },
                        (response) => {
                            // Handle error
                            alert('An error occurred. Please try again.')
                        }
                    );
                }
            },

            verifyRecaptcha() {
                this.recaptchaVerified = true
            },

            handleSelectInitialChange: function (e) {
                // Ensures that when option is selected, colour is white to match other fields.
                e.target.classList.add('is-selected');
            },

            validateEmail(email) {
                const filter = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                return filter.test(email)
            },
        }
    })
}
