//###########################################################################
// Navigation
//###########################################################################
class Navigation {
    constructor() {
        const header = document.querySelector('#header')
        if (header) {
            const hamburgerButton = document.querySelector('.hamburger')
            const menuDropdownTriggers = document.querySelectorAll('.sub-nav__hold > a')
            const menuDropdownBacks = document.querySelectorAll('.sub-nav__back')
            const menuDropdownBacksInner = document.querySelectorAll('.sub-nav__back--dropdown')
            const menuSubDropdownToggles = document.querySelectorAll('.sub-nav-dropdown-heading')
            const menuSubCategoryToggles = document.querySelectorAll('.sub-nav-category-heading')
            const searchButtons = document.querySelectorAll('.site-nav__search')
            const searchCloseBtn = document.querySelector('.search__close')
            // const basketButton = document.querySelector('.site-nav__basket')
            // const basketCross = document.querySelector('.cross--basket')
            let scrolled = false
            let hide = false
            let lastScrollPosition = 0

            hamburgerButton.addEventListener('click', e => {
                mainMenuToggle()
                searchClose()
            })

            for (let i = 0; i < menuDropdownTriggers.length; i++) {
                menuDropdownTriggers[i].addEventListener('click', e => {
                    if (document.documentElement.clientWidth < 993) {
                        e.preventDefault()
                        menuDropdownTriggers[i].parentElement.classList.add('open')
                    }
                })
            }

            for (let i = 0; i < menuSubCategoryToggles.length; i++) {
                menuSubCategoryToggles[i].addEventListener('click', e => {
                    if (document.documentElement.clientWidth < 993) {
                        e.preventDefault()
                        menuSubCategoryToggles[i].parentElement.classList.toggle('open')
                    }
                })
            }

            for (let i = 0; i < menuDropdownBacks.length; i++) {
                menuDropdownBacks[i].addEventListener('click', e => {
                    menuDropdownBacks[i].parentElement.parentElement.parentElement.classList.remove('open')
                })
            }

            for (let i = 0; i < menuDropdownBacksInner.length; i++) {
                menuDropdownBacksInner[i].addEventListener('click', e => {
                    menuDropdownBacksInner[i].parentElement.parentElement.classList.remove('open')
                })
            }

            for (let i = 0; i < menuSubDropdownToggles.length; i++) {
                menuSubDropdownToggles[i].addEventListener('click', e => {
                    menuSubDropdownToggles[i].parentElement.classList.toggle('open')
                })
            }

            for (let i = 0; i < searchButtons.length; i++) {
                searchButtons[i].addEventListener('click', e => {
                    e.preventDefault();
                    searchToggle()
                    mainMenuClose()
                    setTimeout(function () {
                        document.querySelector(".search input").focus();
                    }, 100);
                })
            }

            searchCloseBtn.addEventListener('click', e => {
                e.preventDefault()
                searchClose()
            })

            window.addEventListener('scroll', handleScroll)

            function searchToggle() {
                document.body.classList.add('fix')
                header.classList.toggle('header--search')

                for (let i = 0; i < searchButtons.length; i++) {
                    searchButtons[i].classList.toggle('search--active')
                }
            }

            function searchClose() {
                document.body.classList.remove('fix')
                header.classList.remove('header--search')
                for (let i = 0; i < searchButtons.length; i++) {
                    searchButtons[i].classList.remove('search--active')
                }
            }

            function mainMenuToggle() {
                document.body.classList.toggle('fix')
                header.classList.toggle('header--open')
                hamburgerButton.classList.toggle('open')
            }

            function mainMenuClose() {
                document.body.classList.remove('fix')
                header.classList.remove('header--open')
                hamburgerButton.classList.remove('open')
            }

            function handleScroll() {
                if (window.pageYOffset > 50 && !header.classList.contains('.header--scrolled')) {
                    scrolled = true
                    header.classList.add('header--scrolled')
                } else if (window.pageYOffset < 50) {
                    scrolled = false
                    header.classList.remove('header--scrolled')
                }

                if (document.querySelector('.header--scrolled') && document.querySelector('.stuck')) {
                    document.querySelector('#header').classList.add('header--no-shadow')
                } else {
                    document.querySelector('#header').classList.remove('header--no-shadow')
                }

                const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
                if (currentScrollPosition < 0) {
                    return
                }
                // Stop executing this function if the difference between
                // current scroll position and last scroll position is less than some offset
                if (Math.abs(currentScrollPosition - lastScrollPosition) < 100) {
                    return
                }
                hide = currentScrollPosition > lastScrollPosition
                if (hide) {
                    header.classList.add('header--hide')
                } else {
                    header.classList.remove('header--hide')
                }
                lastScrollPosition = currentScrollPosition
            }
        }
    }
}

export default Navigation
