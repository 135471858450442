//###########################################################################
// How can we help
//###########################################################################

const howCanWeHelp = {
    config: {
        blockHelp: '.block-help',
        iAmSelect: '.block-help__i-am-select',
        iAmSelectFeedback: '.block-help__i-am-select__feedback',
        lookingForSelect: '.block-help__looking-for-select',
        lookingForSelectFeedback: '.block-help__looking-for-select__feedback',
    },

    elements: {
        blockHelp: null,
        iAmSelect: null,
        iAmSelectFeedback: null,
        lookingForSelect: null,
        lookingForSelectFeedback: null
    },

    state: {
        link: '',
        architectOptions: [],
        contractorOptions: [],
        housebuilderOptions: [],
        manufacturerOptions: [],
        merchantOptions: []
    },

    init() {
        const self = this

        self.elements.blockHelp = document.querySelector(self.config.blockHelp)

        if (self.elements.blockHelp !== null) {
            self.elements.iAmSelect = self.elements.blockHelp.querySelector(self.config.iAmSelect)
            self.elements.iAmSelectFeedback = self.elements.blockHelp.querySelector(self.config.iAmSelectFeedback)
            self.elements.lookingForSelect = self.elements.blockHelp.querySelector(self.config.lookingForSelect)
            self.elements.lookingForSelectFeedback = self.elements.blockHelp.querySelector(self.config.lookingForSelectFeedback)

            self.getOptions()
        }
    },

    getOptions() {
        const self = this

        self.state.architectOptions = JSON.parse(self.elements.blockHelp.querySelector('#architectOptions').getAttribute('value'))
        self.state.contractorOptions = JSON.parse(self.elements.blockHelp.querySelector('#contractorOptions').getAttribute('value'))
        self.state.housebuilderOptions = JSON.parse(self.elements.blockHelp.querySelector('#housebuilderOptions').getAttribute('value'))
        self.state.manufacturerOptions = JSON.parse(self.elements.blockHelp.querySelector('#manufacturerOptions').getAttribute('value'))
        self.state.merchantOptions = JSON.parse(self.elements.blockHelp.querySelector('#merchantOptions').getAttribute('value'))

        self.handleSelects()
        self.handleSubmit()
    },

    handleSelects() {
        const self = this

        // Create and select first option 'Looking For'
        //
        self.createLookingForDefaultOption()
        self.elements.lookingForSelect[0].selected = true

        // Set options
        //
        self.setLookingForOptions()

        self.elements.iAmSelect.addEventListener('change', function () {
            if (self.elements.iAmSelect.value !== '') {
                self.elements.iAmSelectFeedback.style.display = 'none'
            }

            // Clear the Looking For select before populating
            //
            self.elements.lookingForSelect.innerHTML = ''

            // Set options
            //
            self.setLookingForOptions()
        })

        self.elements.lookingForSelect.addEventListener('change', function () {
            self.state.link = self.elements.lookingForSelect.value

            if (self.elements.lookingForSelect.value !== '') {
                self.elements.lookingForSelectFeedback.style.display = 'none'
            }
        })
    },

    setLookingForOptions() {
        const self = this

        if (self.elements.iAmSelect.value == 'Architect') {
            self.createLookingForDefaultOption()

            for (let i = 0; i < self.state.architectOptions.length; i++) {
                self.createLookingForOption(self.state.architectOptions[i].lookingFor, self.state.architectOptions[i].targetLink)
            }
        }

        if (self.elements.iAmSelect.value == 'Contractor') {
            self.createLookingForDefaultOption()

            for (let i = 0; i < self.state.contractorOptions.length; i++) {
                self.createLookingForOption(self.state.contractorOptions[i].lookingFor, self.state.contractorOptions[i].targetLink)
            }
        }

        if (self.elements.iAmSelect.value == 'Housebuilder') {
            self.createLookingForDefaultOption()

            for (let i = 0; i < self.state.housebuilderOptions.length; i++) {
                self.createLookingForOption(self.state.housebuilderOptions[i].lookingFor, self.state.housebuilderOptions[i].targetLink)
            }
        }

        if (self.elements.iAmSelect.value == 'Manufacturer') {
            self.createLookingForDefaultOption()

            for (let i = 0; i < self.state.manufacturerOptions.length; i++) {
                self.createLookingForOption(self.state.manufacturerOptions[i].lookingFor, self.state.manufacturerOptions[i].targetLink)
            }
        }

        if (self.elements.iAmSelect.value == 'Merchant') {
            self.createLookingForDefaultOption()

            for (let i = 0; i < self.state.merchantOptions.length; i++) {
                self.createLookingForOption(self.state.merchantOptions[i].lookingFor, self.state.merchantOptions[i].targetLink)
            }
        }
    },

    createLookingForDefaultOption() {
        const self = this

        const option = document.createElement("option")
        option.text = "Looking for..."
        option.value = ""
        option.setAttribute('disabled', true)
        self.elements.lookingForSelect.appendChild(option)

        // Select first option 'Looking for...'
        //
        self.elements.lookingForSelect[0].selected = true
    },

    createLookingForOption(text, value) {
        const self = this

        const option = document.createElement("option")

        if (text !== false) {
            option.text = text
        }

        if (value !== false) {
            option.value = value
        }

        self.elements.lookingForSelect.appendChild(option)
    },

    handleSubmit() {
        const self = this

        self.elements.blockHelp.addEventListener('submit', function (event) {
            event.preventDefault()

            if (self.elements.iAmSelect.value == '') {
                self.elements.iAmSelectFeedback.style.display = ''
            } else if (self.elements.lookingForSelect.value == '') {
                self.elements.lookingForSelectFeedback.style.display = ''
            } else {
                self.elements.iAmSelectFeedback.style.display = 'none'
                self.elements.lookingForSelectFeedback.style.display = 'none'

                if (window.ga) {
                    window.ga('send', 'event', 'How can we help?', 'Submission', self.elements.iAmSelect.value + ' - ' + self.elements.lookingForSelect.options[self.elements.lookingForSelect.selectedIndex].text);
                }

                if (window.gtag) {
                    gtag('event', 'Submission', {
                        'event_category': 'How can we help?',
                        'event_label': self.elements.iAmSelect.value + ' - ' + self.elements.lookingForSelect.options[self.elements.lookingForSelect.selectedIndex].text,
                        'value': 0
                    });
                }

                window.location.assign(self.state.link)
            }
        })
    }
}

window.addEventListener('load', function () {
    howCanWeHelp.init()
})