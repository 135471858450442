//###########################################################################
// sliderProducts
//###########################################################################

import Flickity from 'flickity'
import FlickityFade from 'flickity-fade'
import { throttle } from '../utilities/_throttle-debounce'
require('flickity-sync')
require('flickity-imagesloaded')

const sliderProducts = {
    config: {
        sliderItem: '.slider-item',
        sliderPrev: '.slider__button--prev',
        sliderNext: '.slider__button--next',
        sliderProducts: '.slider-products, .slider-product-categories',
        sliderProductCategoriesContent: '.slider-product-categories-content',
    },

    elements: {
        sliderProducts: null,
        sliderProductCategoriesContent: null,
    },

    init() {
        const self = this

        self.elements.sliderProducts = document.querySelectorAll(self.config.sliderProducts)
        self.elements.sliderProductCategoriesContent = document.querySelectorAll(self.config.sliderProductCategoriesContent)

        if (self.elements.sliderProducts !== null) {
            for (let j = 0; j < self.elements.sliderProducts.length; j++) {
                const sliderProduct = self.elements.sliderProducts[j]

                self.handleSliderProduct(sliderProduct)
            }
        }

        if (self.elements.sliderProductCategoriesContent !== null) {
            for (let h = 0; h < self.elements.sliderProductCategoriesContent.length; h++) {
                const sliderProductCategoriesContent = self.elements.sliderProductCategoriesContent[h]

                self.handleSliderProductCategoriesContent(sliderProductCategoriesContent)
            }
        }
    },

    handleSliderProduct(sliderProduct) {
        const self = this

        // If less than 4 slides set value for logic after init
        //
        let lessThanFourSlides = false
        if (sliderProduct.classList.contains('slider--disabled-large')) {
            lessThanFourSlides = true
        }

        // If 4 slides or less, turn wrapAround off to prevent animation bugs
        //
        const slideCount = (sliderProduct.querySelectorAll(self.config.sliderItem).length)
        let wrapAround = true
        if (slideCount <= 5) {
            wrapAround = false
        }

        const sliderPrev = sliderProduct.querySelector(self.config.sliderPrev)
        const sliderNext = sliderProduct.querySelector(self.config.sliderNext)

        const sliderProductInstance = new Flickity(sliderProduct, {
            pageDots: false,
            prevNextButtons: false,
            wrapAround: wrapAround,
            imagesLoaded: true,
            adaptiveHeight: true,
            cellSelector: '.slider-item',
            cellAlign: 'left',
            on: {
                ready: function () {
                    sliderProduct.classList.add('loaded')
                }
            }
        })

        if (slideCount > 6) {
            sliderPrev.addEventListener('click', function () {
                sliderProductInstance.previous()
            })

            sliderNext.addEventListener('click', function () {
                sliderProductInstance.next()
            })
        } else {
            sliderPrev.addEventListener('click', function () {
                if ((sliderProductInstance.selectedIndex + 1) == 1) {
                    sliderProductInstance.select(sliderProductInstance.cells.length - 1)
                } else {
                    sliderProductInstance.previous()
                }
            })

            sliderNext.addEventListener('click', function () {
                if ((sliderProductInstance.selectedIndex + 1) == sliderProductInstance.cells.length) {
                    sliderProductInstance.select(0)
                } else {
                    sliderProductInstance.next()
                }
            })
        }

        if (lessThanFourSlides) {
            if (window.innerWidth > 1259) {
                sliderProductInstance.options.draggable = false
                sliderProductInstance.updateDraggable()
            } else {
                sliderProductInstance.options.draggable = true
                sliderProductInstance.updateDraggable()
            }

            window.addEventListener('resize', throttle(function() {
                if (window.innerWidth > 1259) {
                    sliderProductInstance.options.draggable = false
                    sliderProductInstance.updateDraggable()
                } else {
                    sliderProductInstance.options.draggable = true
                    sliderProductInstance.updateDraggable()
                }
            }, 500))
        }
    },

    handleSliderProductCategoriesContent(sliderProductCategoriesContent) {
        const self = this

        const sliderProductCategoriesContentInstance = new FlickityFade(sliderProductCategoriesContent, {
            pageDots: false,
            prevNextButtons: false,
            wrapAround: true,
            imagesLoaded: true,
            cellSelector: '.slider-item',
            cellAlign: 'left',
            adaptiveHeight: true,
            fade: true,
            sync: '#slider-product-categories',
            draggable: false,
            on: {
                ready: function () {
                    sliderProductCategoriesContent.classList.add('loaded')
                }
            }
        })
    }
}

window.addEventListener('load', function () {
    sliderProducts.init()
})