//###########################################################################
// Contact form
//###########################################################################

import Vue from 'vue'

Vue.config.productionTip = false
import axios from 'axios'
import {VueRecaptcha} from 'vue-recaptcha'
import {scrollTo} from '../utilities/_scrollTo';

if (document.querySelector('#form-share-shortlist')) {
    let toEmail;

    if (document.querySelector('#form-share-shortlist').getAttribute('data-flooring') == 'true') {
        toEmail = 'flooring@taylor.maxwell.co.uk'
    } else {
        toEmail = 'colours@taylor.maxwell.co.uk'
    }

    new Vue({
        el: '#form-share-shortlist',
        delimiters: ['${', '}'],
        data: {
            errors: [],
            subject: '',
            toEmail: toEmail,
            fromName: null,
            fromEmail: null,
            companyName: null,
            companyPostcode: null,
            projectAddress: null,
            approximateSqmRequired: null,
            message: null,
            requestSamples: null,
            phoneNumber: null,
            emailUpdates: null,
            privacyConfirmation: null,
            recaptchaVerified: false,
            website: ''
        },
        components: {VueRecaptcha},
        methods: {
            send: function (e) {
                e.preventDefault()

                this.errors = []

                if (!this.fromName) {
                    this.errors.push("Name required.")
                }

                if (!this.fromEmail) {
                    this.errors.push('Email required.')
                }

                if (this.fromEmail && !this.validateEmail(this.fromEmail)) {
                    this.errors.push('Email address is incorrect.')
                }

                if (!this.phoneNumber) {
                    this.errors.push('Phone number is required.')
                }

                if (!this.companyName) {
                    this.errors.push('Company name is required.')
                }

                if (!this.companyPostcode) {
                    this.errors.push('Company postcode is required.')
                }

                if (!this.projectAddress) {
                    this.errors.push('Project name/address is required.')
                }

                if (!this.message) {
                    this.errors.push('Message is required.')
                }

                if (!this.privacyConfirmation) {
                    this.errors.push('Please confirm that you have read the Privacy Notice.')
                }

                if (!this.recaptchaVerified) {
                    this.errors.push('Please complete the Google Recaptcha.')
                }

                if (this.website !== '') {
                    this.errors.push('An unknown error occured.')
                }

                if (this.errors.length > 0) {
                    // If there's errors, scroll to them and offset by header (because we're scrolling up)
                    const headerHeight = document.querySelector('#header').clientHeight
                    scrollTo(document.querySelector('#form-share-shortlist'), -headerHeight)
                }

                if (!this.errors.length) {
                    this.errors = []
                    document.querySelector('#form-share-shortlist').classList.add("form--submitting")

                    if (window.ga) {
                        window.ga('send', 'event', 'Form', 'Submission', 'Swatches/Colours Page');
                    }

                    if (window.gtag) {
                        gtag('event', 'Submission', {
                            'event_category': 'Form',
                            'event_label': 'Swatches/Colours Page',
                            'value': 0
                        });
                    }

                    e.target.submit()
                }
            },

            verifyRecaptcha() {
                this.recaptchaVerified = true
            },

            validateEmail(email) {
                const filter = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                return filter.test(email)
            },

            handleSendMeACopy(event) {
                const checkboxInput = event.target

                if (checkboxInput.checked) {
                    let stringToHash = ''
                    if (this.fromEmail !== null && this.validateEmail(this.fromEmail)) {
                        stringToHash = this.toEmail + ',' + this.fromEmail

                        document.querySelector('#toEmail').value = this.getHashEmail(stringToHash)
                    } else {
                        document.querySelector('#toEmail').value = this.getHashEmail(this.toEmail)
                    }
                } else {
                    document.querySelector('#toEmail').value = this.getHashEmail(this.toEmail)
                }
            },

            getHashEmail(string) {
                axios.get('/get-hash-email?s=' + string)
                    .then(res => {
                        document.querySelector('#toEmail').value = res.data.replace(/\s+/g, '')
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        },

        mounted() {
            // Initially clear form
            //
            document.querySelector('#form-share-shortlist form').reset()
        }
    })
}