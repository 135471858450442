//###########################################################################
// Sliders
//###########################################################################

import Flickity from 'flickity'
import FlickityFade from 'flickity-fade'
require('flickity-imagesloaded')
require('flickity-sync')

const sliderDefault = {
	config: {
		slider: '.slider',
		sliderItem: '.slider-item',
		sliderPrev: '.slider__button--prev',
		sliderNext: '.slider__button--next',
		sliderCount: '.slider__count .count',
		sliderCountAmount: '.slider__count .amount',
		sliderProgressBar: '.slider__progress .bar'
	},

	elements: {
		slider: null
	},

	init() {
		const self = this

		self.elements.sliders = document.querySelectorAll(self.config.slider)

		if (self.elements.sliders !== null) {
			for (let i = 0; i < self.elements.sliders.length; i++) {
				const slider = self.elements.sliders[i]

				self.handleSlider(slider)
			}
		}
	},

	handleSlider(slider) {
		const self = this

		const sliderPrev = slider.querySelector(self.config.sliderPrev)
		const sliderNext = slider.querySelector(self.config.sliderNext)

		// If wrap around switched off, then handle controls differently on first/last slides
		//
		const slideCount = (slider.querySelectorAll(self.config.sliderItem).length)
		let wrapAround = true
		if (slider.getAttribute('data-wrap-around') == 'false') {
			wrapAround = false
		}

		// If fade is enable, initialise Flickity differently
		//
		let fade = false
		if (slider.getAttribute('data-fade') == 'true') {
			fade = true
		}

		let sliderInstance = null

		if (fade === true) {
			sliderInstance = new FlickityFade(slider, {
				pageDots: false,
				prevNextButtons: false,
				wrapAround: wrapAround,
				imagesLoaded: true,
				cellSelector: '.slider-item',
				cellAlign: 'left',
				fade: true,
				adaptiveHeight: true,
				on: {
					ready: function () {
						slider.classList.add('loaded')
					}
				}
			})
		} else {
			sliderInstance = new Flickity(slider, {
				pageDots: false,
				prevNextButtons: false,
				wrapAround: wrapAround,
				imagesLoaded: true,
				cellSelector: '.slider-item',
				cellAlign: 'left',
				adaptiveHeight: true,
				on: {
					ready: function () {
						slider.classList.add('loaded')
					}
				}
			})
		}

		sliderPrev.addEventListener('click', function () {
			if (wrapAround === false && (sliderInstance.selectedIndex+1) == 1) {
				sliderInstance.select(sliderInstance.cells.length - 1)
			} else {
				sliderInstance.previous()
			}
		})

		sliderNext.addEventListener('click', function () {
			if (wrapAround === false && (sliderInstance.selectedIndex+1) == sliderInstance.cells.length) {
				sliderInstance.select(0)
			} else {
				sliderInstance.next()
			}
		})

		const sliderCount = slider.querySelector(self.config.sliderCount)
		const sliderCountAmount = slider.querySelector(self.config.sliderCountAmount)
		const sliderProgressBar = slider.querySelector(self.config.sliderProgressBar)

		if (sliderProgressBar !== null) {
			let widthPercentageForProgressBar = self.isWhatPercentOf(1, slideCount)
			sliderProgressBar.style.width = widthPercentageForProgressBar + '%'

			if (!slider.classList.contains('slider-hero')) {
				if (slideCount < 10) {
					sliderCountAmount.innerHTML = '0' + slideCount
				} else {
					sliderCountAmount.innerHTML = slideCount
				}
			}

			sliderInstance.on('change', function(index) {
				if (!slider.classList.contains('slider-hero')) {
					if (index < 9) {
						sliderCount.innerHTML = '0' + (index + 1)
					} else {
						sliderCount.innerHTML = (index + 1)
					}
				}

				// Work out percentage/set progress bar based on current slide index and slide count
				//
				widthPercentageForProgressBar = self.isWhatPercentOf((index+1), slideCount)
				sliderProgressBar.style.width = widthPercentageForProgressBar + '%'
			})
		}
	},

	isWhatPercentOf(numA, numB) {
		return (numA / numB) * 100;
	}
}

window.addEventListener('load', function () {
	sliderDefault.init()
})