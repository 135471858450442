//###########################################################################
// Project filter
//###########################################################################

const projectFilter = {
    config: {
        typeSelect: '.projects-filter__type',
        sectorSelect: '.projects-filter__sector',
        productSelect: '.projects-filter__product',
    },

    elements: {
        typeSelect: null,
        sectorSelect: null,
        productSelect: null
    },

    init() {
        const self = this

        self.elements.typeSelect = document.querySelector(self.config.typeSelect)
        self.elements.sectorSelect = document.querySelector(self.config.sectorSelect)
        self.elements.productSelect = document.querySelector(self.config.productSelect)

        if (self.elements.typeSelect !== null) {
            self.handleSelects()
        }
    },

    handleSelects() {
        const self = this

        self.elements.typeSelect.addEventListener('change', function () {
            window.location = self.getUrl(self.elements.typeSelect.value)

            if (window.ga) {
                window.ga('send', 'event', 'Projects Filters', 'Click', self.elements.typeSelect.value);
            }

            if (window.gtag) {
                gtag('event', 'Click', {
                    'event_category': 'Projects Filters',
                    'event_label': self.elements.typeSelect.value,
                    'value': 0
                });
            }

            return false;
        })

        self.elements.sectorSelect.addEventListener('change', function () {
            window.location = self.getUrl(self.elements.sectorSelect.value)

            if (window.ga) {
                window.ga('send', 'event', 'Projects Filters', 'Click', self.elements.sectorSelect.value);
            }

            if (window.gtag) {
                gtag('event', 'Click', {
                    'event_category': 'Projects Filters',
                    'event_label': self.elements.sectorSelect.value,
                    'value': 0
                });
            }

            return false;
        })

        self.elements.productSelect.addEventListener('change', function () {
            window.location = self.getUrl(self.elements.productSelect.value)

            if (window.ga) {
                window.ga('send', 'event', 'Projects Filters', 'Click', self.elements.productSelect.value);
            }
            if (window.gtag) {
                gtag('event', 'Click', {
                    'event_category': 'Projects Filters',
                    'event_label': self.elements.productSelect.value,
                    'value': 0
                });
            }

            return false;
        })
    },

    getUrl(query) {
        const self = this

        if (query === 'type=all' || query === 'sector=all' || query === 'product=all') {
            return '/projects'
        } else {
            return '?' + query
        }
    }
}

window.addEventListener('load', function () {
    projectFilter.init()
})