//###########################################################################
// sliderMobileOnly
//###########################################################################

import Flickity from 'flickity'
import { throttle } from '../utilities/_throttle-debounce'
require('flickity-imagesloaded')

const sliderMobileOnly = {
    config: {
        sliderItem: '.slider-item',
        sliderPrev: '.slider__button--prev',
        sliderNext: '.slider__button--next',
        sliderMobileOnly: '.slider-mobile-only',
    },

    elements: {
        sliderMobileOnly: null,
    },

    init() {
        const self = this

        self.elements.sliderMobileOnly = document.querySelectorAll(self.config.sliderMobileOnly)

        if (self.elements.sliderMobileOnly !== null) {
            for (let j = 0; j < self.elements.sliderMobileOnly.length; j++) {
                const sliderMobileOnly = self.elements.sliderMobileOnly[j]

                self.handleSliderMobileOnly(sliderMobileOnly)
            }
        }
    },

    handleSliderMobileOnly(sliderMobileOnly) {
        const self = this

        const sliderPrev = sliderMobileOnly.querySelector(self.config.sliderPrev)
        const sliderNext = sliderMobileOnly.querySelector(self.config.sliderNext)

        let sliderMobileOnlyInstance = null

        if (window.innerWidth <= 767) {
            sliderMobileOnlyInstance = new Flickity(sliderMobileOnly, {
                pageDots: false,
                prevNextButtons: false,
                wrapAround: true,
                imagesLoaded: true,
                adaptiveHeight: true,
                cellSelector: '.slider-item',
                cellAlign: 'left',
                on: {
                    ready: function () {
                        sliderMobileOnly.classList.add('loaded')
                    }
                }
            })
        }

        if (sliderPrev !== null && sliderNext !== null) {
            sliderPrev.addEventListener('click', function () {
                sliderMobileOnlyInstance.previous()
            })

            sliderNext.addEventListener('click', function () {
                sliderMobileOnlyInstance.next()
            })
        }

        if (window.innerWidth > 767) {
            if (sliderMobileOnlyInstance !== null) {
                sliderMobileOnlyInstance.destroy()

                const sliderItems = sliderMobileOnlyInstance.getCellElements()
                for (let i = 0; i < sliderItems.length; i++) {
                    sliderItems[i].style.transform = ''
                }
            }
        } else {
            sliderMobileOnlyInstance = new Flickity(sliderMobileOnly, {
                pageDots: false,
                prevNextButtons: false,
                wrapAround: true,
                imagesLoaded: true,
                adaptiveHeight: true,
                cellSelector: '.slider-item',
                cellAlign: 'left',
                on: {
                    ready: function () {
                        sliderMobileOnly.classList.add('loaded')
                    }
                }
            })
        }

        window.addEventListener('resize', throttle(function() {
            if (window.innerWidth > 767) {
                if (sliderMobileOnlyInstance !== null) {
                    sliderMobileOnlyInstance.destroy()

                    const sliderItems = sliderMobileOnlyInstance.getCellElements()
                    for (let i = 0; i < sliderItems.length; i++) {
                        sliderItems[i].style.transform = ''
                    }
                }
            } else {
                sliderMobileOnlyInstance = new Flickity(sliderMobileOnly, {
                    pageDots: false,
                    prevNextButtons: false,
                    wrapAround: true,
                    imagesLoaded: true,
                    adaptiveHeight: true,
                    cellSelector: '.slider-item',
                    cellAlign: 'left',
                    on: {
                        ready: function () {
                            sliderMobileOnly.classList.add('loaded')
                        }
                    }
                })
            }
        }, 500))
    }
}

window.addEventListener('load', function () {
    sliderMobileOnly.init()
})