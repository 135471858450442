//###########################################################################
// sliderFlooring
//###########################################################################

import Flickity from 'flickity'
import FlickityFade from 'flickity-fade'
import { throttle } from '../utilities/_throttle-debounce'
require('flickity-as-nav-for')
require('flickity-imagesloaded')

const sliderFlooring = {
    config: {
        sliderItem: '.slider-item',
        sliderPrev: '.slider__button--prev',
        sliderNext: '.slider__button--next',
        sliderFlooring: '.slider-fs',
        sliderFlooringNav: '.slider-fs-nav',
    },

    elements: {
        sliderFlooring: null,
        sliderFlooringNav: null,
    },

    init() {
        const self = this

        self.elements.sliderFlooring = document.querySelector(self.config.sliderFlooring)
        self.elements.sliderFlooringNav = document.querySelector(self.config.sliderFlooringNav)

        if (self.elements.sliderFlooring !== null) {
            self.handleSliderFlooring()
        }
    },

    handleSliderFlooring() {
        const self = this

        const sliderPrev = document.querySelector(self.config.sliderPrev)
        const sliderNext = document.querySelector(self.config.sliderNext)

        const sliderFlooringInstance = new Flickity(self.elements.sliderFlooring, {
            pageDots: false,
            prevNextButtons: false,
            wrapAround: true,
            imagesLoaded: true,
            adaptiveHeight: true,
            cellSelector: '.slider-item',
            cellAlign: 'left',
            on: {
                ready: function () {
                    self.elements.sliderFlooring.classList.add('loaded')
                }
            }
        })

        sliderPrev.addEventListener('click', function () {
            sliderFlooringInstance.previous()
        })

        sliderNext.addEventListener('click', function () {
            sliderFlooringInstance.next()
        })

        const sliderFlooringNavInstance = new FlickityFade(self.elements.sliderFlooringNav, {
            pageDots: false,
            prevNextButtons: false,
            imagesLoaded: true,
            cellSelector: '.slider-item',
            cellAlign: 'left',
            adaptiveHeight: true,
            asNavFor: '#slider-fs',
            on: {
                ready: function () {
                    self.elements.sliderFlooringNav.classList.add('loaded')
                }
            }
        })
    },
}

window.addEventListener('load', function () {
    sliderFlooring.init()
})