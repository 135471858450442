import Vue from 'vue/dist/vue.js'

// Need to replace as its only jQuery
//import Select2Select2 from 'select2';

if (document.getElementById("key") !== null && document.querySelector('#contact-map') !== null) {
    var GOOGLE_MAP_KEY = document.getElementById("key").value
    var googleScript = document.createElement('script');
    googleScript.type = 'text/javascript';
    googleScript.src = 'https://maps.googleapis.com/maps/api/js?' +
        'key=' + GOOGLE_MAP_KEY + '&libraries=places&callback=window.initContactMap'; //& needed
    document.body.appendChild(googleScript);
}

if (document.querySelector('#contact-map')) {
    window.initContactMap = function initContactMap() {
        new Vue({
            el: '#contact-map',
            delimiters: ['${', '}'],
            data: {
                location: null,
                locationName: null,
                map: null,
                autocomplete: null,
                products: [],
                places: [],
                place: null,
                icon: null,
                markers: [],
                distanceCutOff: 50 //miles
            },

            created() {

            },

            mounted() {
                this.initMap();
            },

            methods: {
                initMap() {
                    this.icon = {
                        url: window.location.protocol + '/dist/img/icons/map-icon.png',
                        scaledSize: new google.maps.Size(40, 62)
                    };

                    this.map = new google.maps.Map(document.getElementById("map"), {
                        center: {
                            lat: Number(document.getElementById("map").getAttribute('data-lat')),
                            lng: Number(document.getElementById("map").getAttribute('data-long')),
                        },
                        zoom: 15,
                        streetViewControl: false,
                        zoomControl: false,
                        styles: [
                            {
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#616161"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative.land_parcel",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#bdbdbd"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#eeeeee"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#757575"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#e5e5e5"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                ]
                            },
                            {
                                "featureType": "road",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#757575"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#dadada"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#e9e9e9"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#949494"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.local",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit.line",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#e5e5e5"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit.station",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#eeeeee"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#c9c9c9"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#30a1b2"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            }
                        ]
                    });

                    const marker = new google.maps.Marker({
                        position: {
                            lat: Number(document.getElementById("map").getAttribute('data-lat')),
                            lng: Number(document.getElementById("map").getAttribute('data-long')),
                        },
                        title: document.getElementById("map").getAttribute('data-title'),
                        map: this.map,
                        icon: this.icon
                    });
                }
            }
        });
    }
}
