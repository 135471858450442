//############################################################################
// Components
//############################################################################

import './_accordions'
import './_contactMap'
import './_cookieMessage'
import './_formBecomeStockist'
import './_formBrickMatching'
import './_formCareerApply'
import './_formContact'
import './_formCpdSeminars'
import './_formRequestAppointment'
import './_formShareShortlist'
import './_howCanWeHelp'
import './_locationMap'
import './_locationFilter'
import './_locationsPostcode'
import './_modal'
import './_newsFilter'
import './_newsletter'
import './_productSelectorFilter'
import './_flooringSelectorFilter'
import './_projectFilter'
import './_resources'
import './_scrollToTriggers'
import './_seminarBooking'
import './_shortlist'
import './_sliderApproach'
import './_sliderDefault'
import './_sliderFlooring'
import './_sliderMobileOnly'
import './_sliderPartners'
import './_sliderProducts'
import './_sliderTiles'
import './_stockist'

import Navigation from './_navigation'
new Navigation()
