//###########################################################################
// Shortlist
//###########################################################################

import Cookies from 'js-cookie'
import { addElement } from '../utilities/_elements'

const shortlist = {
    config: {
        shortlistBar: '.cpd-bar, .fs-bar',
        shortlistHead: '.shortlist__head',
        shortlistHeadCounter: '.shortlist__head .counter',
        shortlistList: '.shortlist__list',
        shortlistListItems: '.shortlist__list__items',
        shortlistAddButton: '.tile-swatch .shortlist__add',
        shortlistEntryAddButton: '.shortlist__full .shortlist__add, .slider-fs .shortlist__add',
        shortlistEntryAddButtonLabel: '.shortlist__add__label',
        shortlistRemoveCardButton: '.shortlist__remove-shortlist',
        shortlistRemoveButton: '.shortlist__remove',
        shortlistClearButton: '.shortlist__clear'
    },

    elements: {
        shortlistBar: null,
        shortlistHead: null,
        shortlistHeadCounter: null,
        shortlistList: null,
        shortlistListItems: null,
        shortlistAddButtons: null,
        shortlistEntryAddButton: null,
        shortlistRemoveShortlistButtons: null,
        shortlistRemoveButtons: null,
        shortlistClearButton: null
    },

    state: {
        shortlistCookie: 'shortlist',
        shortlist: {
            items: {}
        }
    },

    init() {
        const self = this

        self.elements.shortlistAddButtons = document.querySelectorAll(self.config.shortlistAddButton)

        if (self.elements.shortlistAddButtons !== null) {
            self.elements.shortlistEntryAddButton = document.querySelector(self.config.shortlistEntryAddButton)
            self.elements.shortlistListItems = document.querySelector(self.config.shortlistListItems)
            self.elements.shortlistBar = document.querySelector(self.config.shortlistBar)
            self.elements.shortlistHead = document.querySelector(self.config.shortlistHead)
            self.elements.shortlistRemoveCardButtons = document.querySelectorAll(self.config.shortlistRemoveCardButton)
            self.elements.shortlistRemoveButtons = document.querySelectorAll(self.config.shortlistRemoveButton)
            self.elements.shortlistClearButton = document.querySelector(self.config.shortlistClearButton)

            if (self.elements.shortlistBar !== null && self.elements.shortlistBar.classList.contains('fs-bar')) {
                self.state.shortlistCookie = 'shortlist-fs'
            }

            self.handleShortlistAddButtons()
            self.getShortlistCookie()
            self.handleShortlistRemoveButtons()

            if (self.elements.shortlistEntryAddButton !== null) {
                self.handleShortlistEntryAddButton()
            }

            if (self.elements.shortlistHead !== null) {
                self.elements.shortlistHeadCounter = document.querySelector(self.config.shortlistHeadCounter)
                self.handleShortlistHead()
            }

            if (self.elements.shortlistClearButton !== null) {
                self.handleShortlistClearButton()
            }

            if (self.elements.shortlistRemoveCardButtons !== null) {
                self.handleShortlistRemoveCardButtons()
            }
        }
    },

    handleShortlistHead() {
        const self = this

        setTimeout(function () {
            self.elements.shortlistHeadCounter.innerHTML = Object.keys(self.state.shortlist.items).length
        }, 100)

        self.elements.shortlistHead.addEventListener('click', function() {
            if (self.elements.shortlistHead.parentElement.classList.contains('open')) {
                self.elements.shortlistHead.parentElement.classList.remove('open')
            } else {
                self.elements.shortlistHead.parentElement.classList.add('open')
            }
        })
    },

    getShortlistCookie() {
        const self = this

        if (Cookies.get(self.state.shortlistCookie)) {
            self.state.shortlist = JSON.parse(Cookies.get(self.state.shortlistCookie))
        } else {
            Cookies.set(self.state.shortlistCookie, JSON.stringify(self.state.shortlist))
        }

        if (document.querySelector('.shortlist__full') !== null || document.querySelector('.slider-fs') !== null) {
            const shortlistAddButton = document.querySelector(self.config.shortlistEntryAddButton)
            const shortlistAddButtonLabel = shortlistAddButton.querySelector(self.config.shortlistEntryAddButtonLabel)

            if (shortlistAddButton !== null) {
                const id = shortlistAddButton.getAttribute('data-id')

                if (self.state.shortlist.items.hasOwnProperty(id)) {
                    shortlistAddButton.classList.add('is-active')
                    shortlistAddButtonLabel.innerText = 'Remove from shortlist'
                }

                shortlistAddButton.addEventListener('click', function () {
                    if (shortlistAddButton.classList.contains('is-active')) {
                        shortlistAddButton.classList.remove('is-active')
                        shortlistAddButtonLabel.innerText = 'Add to shortlist'
                    } else {
                        shortlistAddButton.classList.add('is-active')
                        shortlistAddButtonLabel.innerText = 'Remove from shortlist'
                    }
                })
            }
        }

        self.generateShortlistMenu()
    },

    generateShortlistMenu() {
        const self = this

        if (self.elements.shortlistListItems !== null) {
            self.elements.shortlistListItems.innerHTML = ''

            if (self.elements.shortlistHead !== null) {
                setTimeout(function () {
                    self.elements.shortlistHeadCounter.innerHTML = Object.keys(self.state.shortlist.items).length
                }, 100)
            }

            if (Object.keys(self.state.shortlist.items).length > 0) {
                for (let item in self.state.shortlist.items) {
                    let data = self.state.shortlist.items[item]

                    addElement({
                        elementTag: 'div',
                        classes: 'shortlist__list__item',
                        setAttributes: {
                            'id': data.id,
                        },
                        innerHTML: '<img src="'+ data.img +'"><div class="shortlist__list__item__content"><a href="' + data.url + '"><p class="medium bump--xs">'+ data.title +'</p></a><button class="shortlist__remove" data-id="'+ data.id + '"><span></span>Remove</button></div>',
                        appendTo: '.shortlist__list__items'
                    })
                }
            } else {
                addElement({
                    elementTag: 'p',
                    classes: 'ta-c bump--none',
                    innerHTML: 'You have 0 products in your list',
                    appendTo: '.shortlist__list__items'
                })
            }
        }

        self.handleShortlistRemoveButtons()
        self.handleActiveCards()
    },

    handleActiveCards() {
        const self = this

        // Clear active buttons if there are any present
        //
        const activeButtons = document.querySelectorAll('.tile-swatch .shortlist__add.is-active')
        if (activeButtons !== null) {
            for (let i = 0; i < activeButtons.length; i++) {
                activeButtons[i].classList.remove('is-active')
            }
        }

        // Clear active entry button if active
        //
        const activeEntryButton = document.querySelector('.shortlist__full .shortlist__add.is-active')
        if (activeEntryButton !== null) {
            const activeEntryButtonLabel = activeEntryButton.querySelector('.shortlist__add__label')
            activeEntryButton.classList.remove('is-active')
            activeEntryButtonLabel.innerText = 'Add to shortlist'
        }

        // For each item in the shortlist, find it's card on the page and set active button
        //
        for (let item in self.state.shortlist.items) {
            let data = self.state.shortlist.items[item]

            const activeButton = document.querySelector('.tile-swatch .shortlist__add[data-id="' + data.id + '"]')
            if (activeButton !== null) {
                activeButton.classList.add('is-active')
            }

            const activeEntryButton = document.querySelector('.shortlist__full .shortlist__add[data-id="' + data.id + '"]')
            if (activeEntryButton !== null) {
                const activeEntryButtonLabel = activeEntryButton.querySelector('.shortlist__add__label')
                activeEntryButton.classList.add('is-active')
                activeEntryButtonLabel.innerText = 'Remove from shortlist'
            }
        }
    },

    handleShortlistAddButtons() {
        const self = this

        for (let i = 0; i < self.elements.shortlistAddButtons.length; i++) {
            const shortlistAddButton = self.elements.shortlistAddButtons[i]

            shortlistAddButton.addEventListener('click', function () {
                if (window.ga) {
                    window.ga('send', 'event', 'Shortlist', 'Click', shortlistAddButton.getAttribute('data-title'));
                }

                if (window.gtag) {
                    gtag('event', 'Click', {
                        'event_category': 'Shortlist',
                        'event_label': shortlistAddButton.getAttribute('data-title'),
                        'value': 0
                    });
                }

                const id = shortlistAddButton.getAttribute('data-id')

                if (self.state.shortlist.items.hasOwnProperty(id)) {
                    delete self.state.shortlist.items[id]

                    Cookies.remove(self.state.shortlistCookie)
                    Cookies.set(self.state.shortlistCookie, JSON.stringify(self.state.shortlist))

                    self.generateShortlistMenu()
                } else {
                    self.state.shortlist.items[id] = {
                        id: shortlistAddButton.getAttribute('data-id'),
                        img: shortlistAddButton.getAttribute('data-image'),
                        title: shortlistAddButton.getAttribute('data-title'),
                        url: shortlistAddButton.getAttribute('data-url'),
                    }

                    Cookies.remove(self.state.shortlistCookie)
                    Cookies.set(self.state.shortlistCookie, JSON.stringify(self.state.shortlist))

                    self.generateShortlistMenu()

                    if (self.elements.shortlistHead !== null) {
                        if (!self.elements.shortlistHead.parentElement.classList.contains('open')) {
                            self.elements.shortlistHead.parentElement.classList.add('open')

                            setTimeout(function() {
                                self.elements.shortlistHead.parentElement.classList.remove('open')
                            }, 2000)
                        }
                    }
                }
            })
        }
    },

    handleShortlistEntryAddButton() {
        const self = this

        const shortlistAddButton = self.elements.shortlistEntryAddButton
        const id = shortlistAddButton.getAttribute('data-id')

        shortlistAddButton.addEventListener('click', function () {
            if (window.ga) {
                window.ga('send', 'event', 'Shortlist', 'Click', shortlistAddButton.getAttribute('data-title'));
            }

            if (window.gtag) {
                gtag('event', 'Click', {
                    'event_category': 'Shortlist',
                    'event_label': shortlistAddButton.getAttribute('data-title'),
                    'value': 0
                });
            }

            if (self.state.shortlist.items.hasOwnProperty(id)) {
                delete self.state.shortlist.items[id]

                Cookies.remove(self.state.shortlistCookie)
                Cookies.set(self.state.shortlistCookie, JSON.stringify(self.state.shortlist))

                self.generateShortlistMenu()
            } else {
                self.state.shortlist.items[id] = {
                    id: shortlistAddButton.getAttribute('data-id'),
                    img: shortlistAddButton.getAttribute('data-image'),
                    title: shortlistAddButton.getAttribute('data-title'),
                    url: shortlistAddButton.getAttribute('data-url'),
                }

                Cookies.remove(self.state.shortlistCookie)
                Cookies.set(self.state.shortlistCookie, JSON.stringify(self.state.shortlist))

                self.generateShortlistMenu()
            }
        })
    },

    handleShortlistRemoveButtons() {
        const self = this

        self.elements.shortlistRemoveButtons = document.querySelectorAll(self.config.shortlistRemoveButton)

        for (let i = 0; i < self.elements.shortlistRemoveButtons.length; i++) {
            const shortlistRemoveButton = self.elements.shortlistRemoveButtons[i]
            const id = shortlistRemoveButton.getAttribute('data-id')

            shortlistRemoveButton.addEventListener('click', function () {
                if (self.state.shortlist.items.hasOwnProperty(id)) {
                    delete self.state.shortlist.items[id]

                    Cookies.remove(self.state.shortlistCookie)
                    Cookies.set(self.state.shortlistCookie, JSON.stringify(self.state.shortlist))

                    self.generateShortlistMenu()
                }
            })
        }
    },

    handleShortlistClearButton() {
        const self = this

        self.elements.shortlistClearButton.addEventListener('click', function () {
            self.state.shortlist.items = {}
            Cookies.remove(self.state.shortlistCookie)
            window.location.reload()
        })
    },

    handleShortlistRemoveCardButtons() {
        const self = this

        for (let i = 0; i < self.elements.shortlistRemoveCardButtons.length; i++) {
            const shortlistRemoveCardButton = self.elements.shortlistRemoveCardButtons[i]

            shortlistRemoveCardButton.addEventListener('click', function() {
                const id = shortlistRemoveCardButton.getAttribute('data-id')
                const cellElement = shortlistRemoveCardButton.closest('.gl__1-4')

                if (self.state.shortlist.items.hasOwnProperty(id)) {
                    delete self.state.shortlist.items[id]

                    Cookies.remove(self.state.shortlistCookie)
                    Cookies.set(self.state.shortlistCookie, JSON.stringify(self.state.shortlist))
                }

                cellElement.remove()

                // Update counter
                //
                document.querySelector('.product-counter').textContent = Object.keys(self.state.shortlist.items).length
            })
        }
    }
}

window.addEventListener('load', function () {
    shortlist.init()
})