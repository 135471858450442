//############################################################################
// Input Change Pusher
//############################################################################

let locationChangers = document.querySelectorAll('.form-pusher');

for (let i = 0; i < locationChangers.length; i++) {
    locationChangers[i].addEventListener('input', function (evt) {
        locationChangers[i].classList.add('changing')

        if (window.ga) {
            window.ga('send', 'event', 'Colours', 'Initial Search', locationChangers[i].options[locationChangers[i].selectedIndex].text);
        }

        if (window.gtag) {
            gtag('event', 'Initial Search', {
                'event_category': 'Colours',
                'event_label': locationChangers[i].options[locationChangers[i].selectedIndex].text,
                'value': 0
            });
        }

        window.location.href = (this.value);
    });
}