//###########################################################################
// Careers apply
//###########################################################################

import Vue from 'vue'

Vue.config.productionTip = false
import {VueRecaptcha} from 'vue-recaptcha'
import {scrollTo} from '../utilities/_scrollTo'
import {postToController} from '../utilities/_postToController'

if (document.querySelector('#form-cpd-seminars')) {
    new Vue({
        el: '#form-cpd-seminars',
        delimiters: ['${', '}'],
        data: {
            errors: [],
            subject: '',
            fullName: null,
            toEmail: null,
            fromEmail: null,
            phoneNumber: null,
            practiceName: null,
            practiceLocation: null,
            privacyConfirmation: false,
            emailUpdates: false,
            recaptchaVerified: false,
            website: ''
        },
        components: {VueRecaptcha},
        methods: {
            send: function (e) {
                e.preventDefault()

                this.errors = [];

                if (!this.fullName) {
                    this.errors.push("Name required.");
                }

                if (!this.fromEmail) {
                    this.errors.push('Email required.');
                }

                if (this.fromEmail && !this.validateEmail(this.fromEmail)) {
                    this.errors.push('Email address is incorrect.');
                }

                if (!this.phoneNumber) {
                    this.errors.push('Phone number is required.');
                }

                if (!this.privacyConfirmation) {
                    this.errors.push('Please agree to our privacy policy.');
                }

                if (!this.recaptchaVerified) {
                    this.errors.push('Please complete the Google Recaptcha.')
                }

                if (this.website !== '') {
                    this.errors.push('An unknown error occured.')
                }

                if (this.errors.length > 0) {
                    // If there's errors, scroll to them and offset by header (because we're scrolling up)
                    const headerHeight = document.querySelector('.site-header').clientHeight
                    scrollTo(document.querySelector('.request-form'), -headerHeight)
                }

                if (!this.errors.length) {

                    let bodyFormData = new FormData()
                    bodyFormData.append('action', 'contact-form/send')
                    bodyFormData.append('subject', this.subject)
                    bodyFormData.append('website', this.website)
                    bodyFormData.append('toEmail', this.toEmail)
                    bodyFormData.append('fromName', this.fullName)
                    bodyFormData.append('fromEmail', this.fromEmail)
                    bodyFormData.append('message[Phone number]', this.phoneNumber)
                    bodyFormData.append('message[Practice name]', this.practiceName)
                    bodyFormData.append('message[Practice location]', this.practiceLocation)
                    bodyFormData.append('message[Privacy confirmation]', this.privacyConfirmation)
                    bodyFormData.append('message[Email updates]', this.emailUpdates)
                    bodyFormData.append('message[Seminars requested]', this.getSeminars())

                    postToController(
                        '', bodyFormData,
                        (response) => {

                            if (window.ga) {
                                window.ga('send', 'event', 'Form', 'Submission', 'CPD Form');
                            }

                            if (window.gtag) {
                                gtag('event', 'Submission', {
                                    'event_category': 'Form',
                                    'event_label': 'CPD Form',
                                    'value': 0
                                });
                            }

                            // On success, hide contact form and show success message.
                            document.querySelector('.form-fields').classList.add("hide");
                            document.querySelector('.success-message').classList.add("show");

                            // Scroll to success message.
                            const headerHeight = document.querySelector('.site-header').clientHeight;
                            scrollTo(document.querySelector('.request-form'), -headerHeight);

                            // Delete 'requestedSeminars' cookie.
                            document.cookie = 'requestedSeminars=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                        },
                        (response) => {
                            // Handle error
                            alert('An error occurred. Please try again.');
                        }
                    );
                }
            },

            validateEmail(email) {
                const filter = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                return filter.test(email)
            },

            verifyRecaptcha() {
                this.recaptchaVerified = true
            },

            getSeminars() {
                let requested = [];
                const seminars = document.querySelectorAll(".requestedSeminars[data-id]");
                seminars.forEach(el => {
                    requested.push(el.value);
                });
                return requested.join(', ');
            },
        },
    })
}
