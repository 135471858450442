//###########################################################################
// Locations Postcode
//###########################################################################

const locationsPostcode = {
    config: {
        locationsPostcodeForm: '#locations-postcode'
    },

    elements: {
        locationsPostcodeForm: null
    },

    init() {
        const self = this

        self.elements.locationsPostcodeForm = document.querySelector(self.config.locationsPostcodeForm)

        if (self.elements.locationsPostcodeForm !== null) {
            const form = self.elements.locationsPostcodeForm
            self.handleNewsletter(form)
        }
    },

    handleNewsletter(form) {
        const self = this

        form.addEventListener('submit', function (e) {
            e.preventDefault()
            let postcode = form.querySelector('input').value

            if (window.ga) {
                window.ga('send', 'event', 'Locations - Postcode', 'Filter', postcode);
            }

            if (window.gtag) {
                gtag('event', 'Filter', {
                    'event_category': 'Locations - Postcode',
                    'event_label': postcode,
                    'value': 0
                });
            }

            form.submit()
        })
    }
}

window.addEventListener('load', function () {
    locationsPostcode.init()
})