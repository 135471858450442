//###########################################################################
// sliderTiles
//###########################################################################

import Flickity from 'flickity'
import { throttle } from '../utilities/_throttle-debounce'
require('flickity-imagesloaded')

const sliderTiles = {
    config: {
        sliderTiles: '.slider-tiles',
        sliderItem: '.slider-item',
        sliderPrev: '.slider__button--prev',
        sliderNext: '.slider__button--next',
    },

    elements: {
        sliderTiles: null,
    },

    init() {
        const self = this

        self.elements.sliderTiles = document.querySelectorAll(self.config.sliderTiles)
        if (self.elements.sliderTiles !== null) {
            for (let k = 0; k < self.elements.sliderTiles.length; k++) {
                const sliderTiles = self.elements.sliderTiles[k]

                self.handleSliderTiles(sliderTiles)
            }
        }
    },

    handleSliderTiles(sliderTiles) {
        const self = this

        // If less than 4 slides set value for logic after init
        //
        let lessThanFourSlides = false
        if (sliderTiles.classList.contains('slider--disabled-large')) {
            lessThanFourSlides = true
        }

        // If 4 slides or less, turn wrapAround off to prevent animation bugs
        //
        const slideCount = (sliderTiles.querySelectorAll(self.config.sliderItem).length)
        let wrapAround = true
        if (slideCount <= 4) {
            wrapAround = false
        }

        const sliderPrev = sliderTiles.querySelector(self.config.sliderPrev)
        const sliderNext = sliderTiles.querySelector(self.config.sliderNext)

        const sliderTilesInstance = new Flickity(sliderTiles, {
            pageDots: false,
            prevNextButtons: false,
            wrapAround: wrapAround,
            imagesLoaded: true,
            cellSelector: '.slider-item',
            cellAlign: 'left',
            on: {
                ready: function () {
                    sliderTiles.classList.add('loaded')
                }
            }
        })

        sliderPrev.addEventListener('click', function () {
            if (wrapAround === false && (sliderTilesInstance.selectedIndex+1) == 1 && window.innerWidth >= 768) {
                sliderTilesInstance.select(sliderTilesInstance.cells.length - 1)
            } else {
                sliderTilesInstance.previous()
            }
        })

        sliderNext.addEventListener('click', function () {
            if (wrapAround === false && (sliderTilesInstance.selectedIndex+1) == sliderTilesInstance.cells.length && window.innerWidth >= 768) {
                sliderTilesInstance.select(0)
            } else {
                sliderTilesInstance.next()
            }
        })

        if (lessThanFourSlides) {
            if (window.innerWidth > 1259) {
                sliderTilesInstance.options.draggable = false
                sliderTilesInstance.updateDraggable()
            } else {
                sliderTilesInstance.options.draggable = true
                sliderTilesInstance.updateDraggable()
            }

            if (window.innerWidth < 768) {
                sliderTilesInstance.options.wrapAround = true
            }

            window.addEventListener('resize', throttle(function() {
                if (window.innerWidth > 1259) {
                    sliderTilesInstance.options.draggable = false
                    sliderTilesInstance.updateDraggable()
                } else {
                    sliderTilesInstance.options.draggable = true
                    sliderTilesInstance.updateDraggable()
                }

                if (window.innerWidth < 768) {
                    sliderTilesInstance.options.wrapAround = true
                }
            }, 500))
        }
    },
}

window.addEventListener('load', function () {
    sliderTiles.init()
})