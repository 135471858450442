/**
*   Cookie declarations.
*   ------------------------------
*/
const cookieName = 'requestedSeminars';
const cookieLength = 3; // Days

/**
*   Seminar request page specific JS.
*   ------------------------------
*/
if (document.querySelector('#seminar-request')) {

    // Don't allow clicking 'Book Now' if button is disabled.
    document.getElementById("book-now").addEventListener('click', function (event) {
        if (this.classList.contains('disabled')) {
            event.preventDefault();
        }
    });

    /**
    *   Handle when a user clicks on any 'Add Seminar' button.
    */
    const addBtns = document.querySelectorAll(".add-seminar");
    addBtns.forEach(el => {
        el.addEventListener('click', (e) => {
            let seminarId = e.target.getAttribute('data-id');
            addSeminar( seminarId );
            setBookBtnState(); // Enable clicking on 'Book Now' as a seminar has been added.
            populateSelectedCount(); // Update seminar selected count.
            toggleSeminarButton(seminarId, 'remove-seminar'); // Added a seminar, therefore now present the 'remove-seminar' button for the same seminar.
            e.preventDefault();
        });
    });

    // On page load, set the button states.
    setButtonState();
    setBookBtnState();
}

/**
*   Seminar request or booking page JS.
*   ------------------------------
*/
if (document.querySelector('#seminar-request') || document.querySelector('#seminar-booking'))
{
    // Present a count of how many seminars selected.
    if (document.getElementById("selectedCount")) { populateSelectedCount(); }

    /**
    *   Handle when a user clicks to remove a seminar.
    */
    const removeBtns = document.querySelectorAll(".remove-seminar");
    removeBtns.forEach(el => {
        el.addEventListener('click', (e) => {

            let seminarId = e.target.getAttribute('data-id');
            removeSeminar( seminarId );

            if (document.querySelector('#seminar-request')) { // Seminar Request page.
                // Since we're removing a seminar, show the 'add-seminar' button and hide the 'remove-seminar'
                setBookBtnState(); toggleSeminarButton(seminarId, 'add-seminar');
            }
            if (document.querySelector('#seminar-booking')) { // Seminar booking page.
                e.target.closest('.seminar').remove(); // Remove the entire seminar row, which also includes the hidden input for the contact form.
            }
            populateSelectedCount();
            e.preventDefault();
        });
    });
}

/**
*   FUNCTIONS
*   ------------------------------
*/
/**
*   Add a seminar to the cookie.
*   @param integer {seminarId}: The id of the seminar.
*/
function addSeminar(seminarId) {
    if (!getCookie(cookieName)) { // Cookie doesn't exist, so create a new one.
        setCookie(cookieName, seminarId, cookieLength);
    } else { // Cookie already exists, so append seminar to existing.
        setCookie(cookieName, removeDuplicateSeminars( getCookie(cookieName) + ',' + seminarId ), cookieLength); // Store new seminar into cookie as CSV
    }
}
/**
*   Remove a seminar from the cookie.
*   @param integer {seminarId}: The id of the seminar.
*/
function removeSeminar(seminarId)
{
    let seminars = getCookie(cookieName).split(',');
    let index = seminars.indexOf(seminarId);
    if (index > -1) { seminars.splice(index, 1); } // Seminar found in cookie, therefore remove.
    setCookie(cookieName, removeDuplicateSeminars( seminars.join(',') ), cookieLength);
}
/**
*   Remove any duplicate seminars before storing into cookie (i.e. ensure the
*   same seminar hasn't been selected multiple times).
*   @param string {str}: the cookie string in CSV.
*/
function removeDuplicateSeminars(str) {
    var seminars = str.split(',');
    var cleanSeminars = [];
    for(var i = 0; i < seminars.length; i++){
        if (cleanSeminars.indexOf(seminars[i]) === -1) {
            cleanSeminars.push(seminars[i]);
        }
    }
    return cleanSeminars;
}
/**
*   Toggle the seminar button from 'Add Seminar' to 'Remove Seminar' on the
*   seminar request page.
*   @param integer {seminarId}: The id of the seminar button state to toggle.
*   @param string {toggle} (optional): the state to toggle to, e.g. 'add-seminar' to show the button.
*/
function toggleSeminarButton(seminarId, toggle)
{
    if (toggle == null) { toggle = 'add-seminar'; } // default to 'add-seminar'
    const allBtns = document.querySelectorAll(`[data-id='${seminarId}']`);
    allBtns.forEach(el => {
        if (el.classList.contains(toggle)) {
            el.classList.add('show');
        } else {
            el.classList.remove('show');
        }
    })
}
/**
*   On page load, set the seminar button state(s). If a seminar has already been
*   selected by the user, change the button from 'Add Seminar' to 'Remove Seminar'
*   after reading the cookie.
*/
function setButtonState()
{
    let cookie = getCookie(cookieName);
    if (cookie) {
        var seminars = cookie.split(',');
        for(var i = 0; i < seminars.length; i++){
            toggleSeminarButton(seminars[i], 'remove-seminar');
        }
    }
}
/**
*   On page load, set the 'Book Now' button state. Enabled if one or more
*   seminars have been selected, otherwise disable the button.
*/
function setBookBtnState() {
    let btn = document.getElementById("book-now");
    if (getCookie(cookieName)) { // The seminar cookie exists, so enable the button.
        btn.classList.remove('disabled');
    } else { // No cookie and therefore no seminars selected, disable the button.
        btn.classList.add('disabled');
    }
}
/**
*   Count how many seminars have been selected by the user by reading the cookie.
*   @return integer.
*/
function selectedCount() {
    let cookie = getCookie(cookieName);
    if (cookie) { return cookie.split(',').length; }
    return 0;
}
/**
*   Call this function to update the seminar selected count on the seminar
*   request and booking pages.
*/
function populateSelectedCount()
{
    document.getElementById("selectedCount").innerHTML = selectedCount();
}

/**
*   Cookie functions.
*   ------------------------------
*/
function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
function eraseCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
