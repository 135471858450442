//###########################################################################
// sliderApproach
//###########################################################################

import Flickity from 'flickity'
require('flickity-imagesloaded')

const sliderApproach = {
    config: {
        sliderApproach: '.slider-approach',
        sliderItem: '.slider-item',
        sliderPrev: '.slider__button--prev',
        sliderNext: '.slider__button--next',
        sliderCount: '.slider__count .count',
        sliderCountAmount: '.slider__count .amount',
        sliderProgressBar: '.slider__progress .bar'
    },

    elements: {
        sliderApproach: null
    },

    init() {
        const self = this

        self.elements.sliderApproach = document.querySelector(self.config.sliderApproach)
        if (self.elements.sliderApproach !== null) {
            self.handleSliderApproach(self.elements.sliderApproach)
        }
    },

    handleSliderApproach(sliderApproach) {
        const self = this

        const slideCount = (sliderApproach.querySelectorAll(self.config.sliderItem).length)

        const sliderPrev = sliderApproach.querySelector(self.config.sliderPrev)
        const sliderNext = sliderApproach.querySelector(self.config.sliderNext)

        const sliderApproachInstance = new Flickity(sliderApproach, {
            pageDots: false,
            prevNextButtons: false,
            wrapAround: false,
            imagesLoaded: true,
            cellSelector: '.slider-item',
            cellAlign: 'left',
            on: {
                ready: function () {
                    sliderApproach.classList.add('loaded')
                }
            }
        })

        sliderPrev.addEventListener('click', function () {
            if ((sliderApproachInstance.selectedIndex+1) == 1) {
                sliderApproachInstance.select(sliderApproachInstance.cells.length - 1)
            } else {
                sliderApproachInstance.previous()
            }
        })

        sliderNext.addEventListener('click', function () {
            if ((sliderApproachInstance.selectedIndex+1) == sliderApproachInstance.cells.length) {
                sliderApproachInstance.select(0)
            } else {
                sliderApproachInstance.next()
            }
        })

        const sliderCount = sliderApproach.querySelector(self.config.sliderCount)
        const sliderCountAmount = sliderApproach.querySelector(self.config.sliderCountAmount)
        const sliderProgressBar = sliderApproach.querySelector(self.config.sliderProgressBar)

        let widthPercentageForProgressBar = self.isWhatPercentOf(1, slideCount)
        sliderProgressBar.style.width = widthPercentageForProgressBar + '%'

        if (sliderCount !== null) {
            if (slideCount < 10) {
                sliderCountAmount.innerHTML = '0' + slideCount
            } else {
                sliderCountAmount.innerHTML = slideCount
            }

            sliderApproachInstance.on('change', function(index) {
                if (index < 9) {
                    sliderCount.innerHTML = '0' + (index + 1)
                } else {
                    sliderCount.innerHTML = (index + 1)
                }

                // Work out percentage/set progress bar based on current slide index and slide count
                //
                widthPercentageForProgressBar = self.isWhatPercentOf((index+1), slideCount)
                sliderProgressBar.style.width = widthPercentageForProgressBar + '%'
            })
        }
    },

    isWhatPercentOf(numA, numB) {
        return (numA / numB) * 100;
    }
}

window.addEventListener('load', function () {
    sliderApproach.init()
})