//###########################################################################
// Scroll to
//###########################################################################

import { scrollTo } from '../utilities/_scrollTo'

const scrollToTriggers = {
    config: {
        scrollTo: '.scroll-to'
    },

    elements: {
        scrollToTriggers: null
    },

    init() {
        const self = this

        self.elements.scrollToTriggers = document.querySelectorAll(self.config.scrollTo)

        if (self.elements.scrollToTriggers !== null) {
            for (let i = 0; i < self.elements.scrollToTriggers.length; i++) {
                const scrollToTrigger = self.elements.scrollToTriggers[i]

                self.handleScrollToTrigger(scrollToTrigger)
            }
        }
    },

    handleScrollToTrigger(scrollToTrigger) {
        const self = this

        const scrollTarget = document.querySelector(scrollToTrigger.getAttribute('href'))

        scrollToTrigger.addEventListener('click', function (e) {
            e.preventDefault()

            const headerHeight = document.querySelector('#header').clientHeight
            scrollTo(scrollTarget, -headerHeight)
        })
    }
}

window.addEventListener('load', function () {
    scrollToTriggers.init()
})