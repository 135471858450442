//###########################################################################
// News filter
//###########################################################################

const newsFilter = {
    config: {
        topicSelect: '.news-filter #topic',
        serviceSelect: '.news-filter #service',
    },

    elements: {
        topicSelect: null,
        serviceSelect: null
    },

    state: {
        topic: 'topic=all',
        service: 'section=all'
    },

    init() {
        const self = this

        self.elements.topicSelect = document.querySelector(self.config.topicSelect)
        self.elements.serviceSelect = document.querySelector(self.config.serviceSelect)

        if (self.elements.topicSelect !== null && self.elements.serviceSelect !== null) {
            self.handleSelects()
        }
    },

    handleSelects() {
        const self = this

        self.state.topic = self.elements.topicSelect.value
        self.state.service = self.elements.serviceSelect.value

        self.elements.topicSelect.addEventListener('change', function () {
            self.state.topic = self.elements.topicSelect.value

            window.location = self.getUrl();

            return false;
        })

        self.elements.serviceSelect.addEventListener('change', function () {
            self.state.service = self.elements.serviceSelect.value

            window.location = self.getUrl();

            return false;
        })
    },

    getUrl() {
        const self = this

        return '?' + self.state.topic + '&' + self.state.service
    }
}

window.addEventListener('load', function () {
    newsFilter.init()
})