//############################################################################
// Scroll to
//############################################################################

export const scrollTo = (element, offset = 0) => {

    let elementToScrollTo = null
    if (typeof element === 'string') {
        elementToScrollTo = document.querySelector(element)
    } else {
        elementToScrollTo = element
    }

    let elementTop = elementToScrollTo.getBoundingClientRect().top
    let scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
    let scrollDistance = elementTop + scrollTop + offset

    window.scroll({
        top: scrollDistance,
        left: 0,
        behavior: 'smooth'
    });
}

// Scroll to element via data-scroll-to attribute
//
const headerHeight = document.querySelector('#header').clientHeight + 40
let scrollToElems = document.querySelectorAll('[data-scroll-to]');

for (let i = 0; i < scrollToElems.length; i++) {
    scrollToElems[i].addEventListener('click', function (e) {
        e.preventDefault();
        scrollTo(scrollToElems[i].getAttribute('data-scroll-to'), -headerHeight)
    });
}
