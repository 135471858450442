//###########################################################################
// sliderPartners
//###########################################################################

import FlickityFade from 'flickity-fade'
require('flickity-imagesloaded')
require('flickity-sync')

const sliderPartners = {
    config: {
        sliderPartners: '.slider-partners-content',
    },

    elements: {
        sliderPartners: null,
    },

    init() {
        const self = this

        self.elements.sliderPartners = document.querySelectorAll(self.config.sliderPartners)
        if (self.elements.sliderPartners !== null) {
            for (let a = 0; a < self.elements.sliderPartners.length; a++) {
                const sliderPartners = self.elements.sliderPartners[a]

                self.handleSliderPartners(sliderPartners)
            }
        }
    },

    handleSliderPartners(sliderPartners) {
        const self = this

        const sliderPartnersInstance = new FlickityFade(sliderPartners, {
            pageDots: false,
            prevNextButtons: false,
            wrapAround: true,
            imagesLoaded: true,
            cellSelector: '.slider-item',
            cellAlign: 'left',
            adaptiveHeight: true,
            fade: true,
            sync: '#slider-partners',
            draggable: false,
            on: {
                ready: function () {
                    sliderPartners.classList.add('loaded')
                }
            }
        })
    }
}

window.addEventListener('load', function () {
    sliderPartners.init()
})