//###########################################################################
// Location filter
//###########################################################################

const locationFilter = {
    config: {
        locationFilter: '.location-filter',
        allCheckbox: '.checkbox input#allLocations',
        facadesCheckbox: '.checkbox input#facadesServices',
        timberCheckbox: '.checkbox input#timberServices',
        resultsContainer: '#location-filter__results',
        item: '.location-filter__item'
    },

    elements: {
        locationFilter: null,
        allCheckbox: null,
        facadesCheckbox: null,
        timberCheckbox: null,
        resultsContainer: null
    },

    init() {
        const self = this

        self.elements.locationFilter = document.querySelector(self.config.locationFilter)

        if (self.elements.locationFilter !== null) {
            self.elements.allCheckbox = self.elements.locationFilter.querySelector(self.config.allCheckbox)
            self.elements.facadesCheckbox = self.elements.locationFilter.querySelector(self.config.facadesCheckbox)
            self.elements.timberCheckbox = self.elements.locationFilter.querySelector(self.config.timberCheckbox)
            self.elements.resultsContainer = document.querySelector(self.config.resultsContainer)
            self.elements.items = self.elements.resultsContainer.querySelectorAll(self.config.item)

            self.handleCheckboxes()
        }
    },

    handleCheckboxes() {
        const self = this

        self.elements.allCheckbox.addEventListener('change', function () {
            if (self.elements.allCheckbox.checked === true) {

                if (window.ga) {
                    window.ga('send', 'event', 'Locations', 'Filter', 'All Locations');
                }

                if (window.gtag) {
                    gtag('event', 'Filter', {
                        'event_category': 'Locations',
                        'event_label': 'All Locations',
                        'value': 0
                    });
                }

                self.elements.facadesCheckbox.checked = false
                self.elements.timberCheckbox.checked = false

                for (let i = 0; i < self.elements.items.length; i++) {
                    const item = self.elements.items[i]

                    if (item.classList.contains('location-filter__item--hidden')) {
                        item.classList.remove('location-filter__item--hidden')
                    }
                }
            } else {
                for (let i = 0; i < self.elements.items.length; i++) {
                    const item = self.elements.items[i]

                    if (item.classList.contains('location-filter__item--hidden')) {
                        item.classList.remove('location-filter__item--hidden')
                    }
                }
            }
        })

        self.elements.facadesCheckbox.addEventListener('change', function () {
            if (self.elements.facadesCheckbox.checked === true) {

                if (window.ga) {
                    window.ga('send', 'event', 'Locations', 'Filter', 'Facades Locations');
                }

                if (window.gtag) {
                    gtag('event', 'Filter', {
                        'event_category': 'Locations',
                        'event_label': 'Facades Locations',
                        'value': 0
                    });
                }

                self.elements.allCheckbox.checked = false
                self.elements.timberCheckbox.checked = false
                for (let i = 0; i < self.elements.items.length; i++) {
                    const item = self.elements.items[i]

                    if (item.classList.contains('location-filter__item--hidden')) {
                        item.classList.remove('location-filter__item--hidden')
                    }

                    if (!item.classList.contains('location-filter__item--facades')) {
                        item.classList.add('location-filter__item--hidden')
                    }
                }
            } else {
                for (let i = 0; i < self.elements.items.length; i++) {
                    const item = self.elements.items[i]

                    if (item.classList.contains('location-filter__item--hidden')) {
                        item.classList.remove('location-filter__item--hidden')
                    }
                }
            }
        })

        self.elements.timberCheckbox.addEventListener('change', function () {
            if (self.elements.timberCheckbox.checked === true) {

                if (window.ga) {
                    window.ga('send', 'event', 'Locations', 'Filter', 'Timber Locations');
                }


                if (window.gtag) {
                    gtag('event', 'Filter', {
                        'event_category': 'Locations',
                        'event_label': 'Timber Locations',
                        'value': 0
                    });
                }

                self.elements.allCheckbox.checked = false
                self.elements.facadesCheckbox.checked = false

                for (let i = 0; i < self.elements.items.length; i++) {
                    const item = self.elements.items[i]

                    if (item.classList.contains('location-filter__item--hidden')) {
                        item.classList.remove('location-filter__item--hidden')
                    }

                    if (!item.classList.contains('location-filter__item--timber')) {
                        item.classList.add('location-filter__item--hidden')
                    }
                }
            } else {
                for (let i = 0; i < self.elements.items.length; i++) {
                    const item = self.elements.items[i]

                    if (item.classList.contains('location-filter__item--hidden')) {
                        item.classList.remove('location-filter__item--hidden')
                    }
                }
            }
        })
    }
}

window.addEventListener('load', function () {
    locationFilter.init()
})