/**
*   Open the corresponding modal when a '.modal-trigger' class has been clicked.
*/
const triggerButtons = document.querySelectorAll(".modal-trigger");
triggerButtons.forEach(el=>{
    el.addEventListener('click', (e) => {
        openModal(e.target.getAttribute('data-modal'));
    });
});
/**
*   Close the modal when clicked on the close button.
*/
const closeButtons = document.querySelectorAll(".modal-close");
closeButtons.forEach(el=>{
    el.addEventListener('click', (e) => {
        closeModal(e.target.closest('.modal').getAttribute('id'));
    });
});
/**
*   When the user/visitor clicks anywhere outside the modal, close the modal too.
*/
window.onclick = function(e) {
    let element = e.target;
    if (element.classList.contains('modal'))
    {
        closeModal(element.getAttribute('id'));
    }
}
/**
*   Open modal function.
*/
function openModal(name)
{
    let modal = document.getElementById(name);
    modal.style.display = "block";
}
/**
*   Close modal function.
*/
function closeModal(name)
{
    let modal = document.getElementById(name);
    modal.style.display = "none";
}
