//###########################################################################
// Accordions
//###########################################################################

const accordions = {
    config: {
        accordionContainer: '.accordion__container',
        accordionItem: '.accordion__item',
        accordionHeader: '.accordion__header',
        accordionTrigger: '.accordion__trigger'
    },

    elements: {
        accordions: null
    },

    init() {
        const self = this

        self.elements.accordions = document.querySelectorAll(self.config.accordionContainer)

        if (self.elements.accordions !== null) {
            for (let i = 0; i < self.elements.accordions.length; i++) {
                const accordion = self.elements.accordions[i]

                self.handleAccordion(accordion)
            }
        }
    },

    handleAccordion(accordion) {
        const self = this

        const accordionItems = accordion.querySelectorAll(self.config.accordionItem)

        for (let i = 0; i < accordionItems.length; i++) {
            const accordionItem = accordionItems[i]

            self.handleAccordionItem(accordionItem, accordionItems)
        }
    },

    handleAccordionItem(accordionItem, accordionItems) {
        const self = this

        const accordionHeader = accordionItem.querySelector(self.config.accordionHeader)
        const accordionTrigger = accordionItem.querySelector(self.config.accordionTrigger)

        accordionHeader.addEventListener('click', function (e) {
            if (e.target !== accordionTrigger) {
                accordionTrigger.click()
            }
        })

        accordionTrigger.addEventListener('click', function () {
            // Toggle the open class
            //
            if (accordionItem.classList.contains('open')) {
                accordionItem.classList.remove('open')
            } else {
                // Close all currently open accordions
                //
                for (let i = 0; i < accordionItems.length; i++) {
                    const accordionItemToClose = accordionItems[i]

                    if (accordionItemToClose.classList.contains('open')) {
                        accordionItemToClose.classList.remove('open')
                    }
                }

                accordionItem.classList.add('open')
            }
        })
    }
}

window.addEventListener('load', function () {
    accordions.init()
})