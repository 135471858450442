window.onload = () => {

    // Specific GA Event handlers
    let customLinks = document.querySelectorAll('[data-ga-event]');
    for (let i = 0; i < customLinks.length; i++) {
        customLinks[i].addEventListener("click", (e) => {

            let category = customLinks[i].dataset.gaCategory;
            let action = customLinks[i].dataset.gaAction;
            let label = customLinks[i].dataset.gaLabel;

            //console.log("Category: " + category + " | " + "Action: " + action + " | " + "Label: " + label);

            if (window.ga) {
                window.ga('send', 'event', category, action, label);
            }

            if (window.gtag) {
                gtag('event', action, {
                    'event_category': category,
                    'event_label': label,
                    'value': 0
                });
            }

        });
    }

    // Generic outbound links
    const filetypes = /\.(zip|exe|dmg|pdf|doc.*|docx|xls.*|ppt.*|mp3|txt|rar|wma|mov|avi|wmv|flv|wav)$/i;
    let baseHref = window.location.href;
    let links = document.querySelectorAll('a');
    for (let i = 0; i < links.length; i++) {
        links[i].addEventListener("click", (e) => {

            let el = links[i];
            let track = true;
            let href = (typeof (el.getAttribute('href')) != 'undefined') ? el.getAttribute('href') : "";

            let isThisDomain = href.match(document.domain.split('.').reverse()[1] + '.' + document.domain.split('.').reverse()[0]);

            let elEv = [];

            elEv.value = 0;
            elEv.non_i = false;

            if (href.match(/^mailto\:/i)) {
                elEv.category = "Mail";
                elEv.action = "Click";
                elEv.label = href.replace(/^mailto\:/i, '');
                elEv.loc = href;

            } else if (href.match(filetypes)) {

                let extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : undefined;

                elEv.category = "Downloads";
                elEv.action = "Click";

                elEv.label = extension[0].toUpperCase();

                if (links[i].getAttribute('data-filename')) {
                    elEv.label = $(this).data('filename');
                } else {
                    elEv.label = href.replace(/ /g, "-");
                }

                elEv.loc = baseHref + href;

            } else if (href.match(/^https?\:/i) && !isThisDomain) {
                elEv.category = "Outbound links";
                elEv.action = "Click";
                elEv.label = href.replace(/^https?\:\/\//i, '');
                elEv.non_i = true;
                elEv.loc = href;

            } else if (href.match(/^tel\:/i)) {
                elEv.category = "Telephone";
                elEv.action = "Click";
                elEv.label = href.replace(/^tel\:/i, '');
                elEv.loc = href;

            } else {
                track = false;
            }

            if (track) {

                console.log("Category: " + elEv.category + " | " + "Action: " + elEv.action + " | " + "Label: " + elEv.label + " | " + "Value: " + elEv.value);

                if (window.ga) {
                    window.ga('send', 'event', elEv.category, elEv.action, elEv.label, elEv.value);
                }

                if (window.gtag) {
                    gtag('event', elEv.action, {
                        'event_category': elEv.category,
                        'event_label': elEv.label,
                        'value': elEv.value
                    });
                }

                if (el.getAttribute('target') == undefined || el.getAttribute('target').toLowerCase() != '_blank') {
                    setTimeout(function () {
                        location.href = elEv.loc;
                    }, 400);
                    return false;
                }
            }
        });
    }
};