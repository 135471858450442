import Vue from 'vue/dist/vue.js'
import {scrollTo} from '../utilities/_scrollTo'
// Need to replace as its only jQuery
//import Select2Select2 from 'select2';

if (document.getElementById("key") !== null && document.querySelector('#stockists') !== null) {
    var GOOGLE_MAP_KEY = document.getElementById("key").value
    var googleScript = document.createElement('script');
    googleScript.type = 'text/javascript';
    googleScript.src = 'https://maps.googleapis.com/maps/api/js?' +
        'key=' + GOOGLE_MAP_KEY + '&libraries=places&callback=window.initStockMap'; //& needed
    document.body.appendChild(googleScript);
}

if (document.querySelector('#stockists')) {
    window.initStockMap = function initStockMap() {
        new Vue({
            el: '#stockists',
            delimiters: ['${', '}'],
            data: {
                allStockists: [],
                stockists: [],
                location: null,
                locationName: null,
                map: null,
                autocomplete: null,
                products: [],
                places: [],
                place: null,
                icon: null,
                markers: [],
                distanceCutOff: 50 //miles
            },

            created() {
                let allStockists = document.querySelector('#allStockists').value;
                this.allStockists = JSON.parse(allStockists);
                this.stockists = this.allStockists;
            },

            mounted() {
                this.initProducts();
                this.initMap();
            },

            methods: {
                initMap() {
                    this.icon = {
                        url: window.location.protocol + '/dist/img/icons/map-icon-composite-prime.png',
                        scaledSize: new google.maps.Size(30, 41)
                    };

                    this.map = new google.maps.Map(document.getElementById("map"), {
                        center: {
                            lat: 53.1181885,
                            lng: -1.6098285
                        },
                        zoom: 6.7,
                        streetViewControl: false,
                        styles: [
                            {
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#616161"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative.land_parcel",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#bdbdbd"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#eeeeee"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#757575"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#e5e5e5"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                ]
                            },
                            {
                                "featureType": "road",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#757575"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#dadada"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#616161"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.local",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit.line",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#e5e5e5"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit.station",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#eeeeee"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#dedede"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                ]
                            }
                        ]
                    });

                    this.autocomplete = new google.maps.places.Autocomplete((document.getElementById('location')), {
                        componentRestrictions: {'country': 'uk'},
                        types: ['(regions)'],
                        fields: ['address_component', 'geometry']
                    });
                    this.autocomplete.addListener('place_changed', this.onPlaceChanged);
                    this.places = new google.maps.places.PlacesService(this.map);

                    this.setMarkers();
                },

                initProducts() {
                    function formatLogo(logo) {

                        if (!logo.id) {
                            return logo.text;
                        }

                        if (logo.element) {
                            var baseUrl = logo.element.getAttribute('data-logo');
                            var logo = '<span><img src="' + baseUrl + '" class="img-logo" /> ' + logo.text + '</span>';
                            return logo;
                        }
                    };
                },

                setMarkers() {
                    //REMOVE EXISTING MARKERS
                    for (var i = 0; i < this.markers.length; i++) {
                        this.markers[i].setMap(null);
                    }

                    this.markers = [];
                    const vm = this;

                    var bounds = new google.maps.LatLngBounds();

                    for (var i = 0; i < this.stockists.length; i++) {
                        var lat = parseFloat(this.stockists[i].location.lat);
                        var lng = parseFloat(this.stockists[i].location.lng);
                        var title = this.stockists[i].title;
                        const marker = new google.maps.Marker({
                            position: {
                                lat: lat,
                                lng: lng,
                            },
                            title: title,
                            map: this.map,
                            icon: this.icon
                        });

                        marker.data = this.stockists[i];

                        bounds.extend(marker.position);

                        var infowindow = new google.maps.InfoWindow();
                        infowindow.close();

                        google.maps.event.addListener(marker, 'click', function (e) {
                            var infoHtml = '<div class="item">' +
                                '<span class="p medium">' + this.data.title + '</span>' +
                                '<div class="small">' + this.data.address + '</div>' +
                                '<p class="small small-print">';

                            if (this.data.phoneNumber) {
                                infoHtml += 't: <a href="tel:' + this.data.phoneNumber + '">' + this.data.phoneNumber + '</a><br>';
                            }

                            if (this.data.emailAddress) {
                                infoHtml += 'e: <a href="mailto:' + this.data.emailAddress + '">' + this.data.emailAddress + '</a><br>';
                            }

                            if (this.data.webAddress) {
                                infoHtml += 'w: <a target="_blank" href="' + this.data.webAddress + '" rel="noopender">' + this.data.webAddress + '</a><br>';
                            }

                            infoHtml += '</p>';

                            if (vm.location) {
                                infoHtml += '<div class="small"><a target="_blank" href="https://www.google.com/maps/dir//' + this.data.addressUrl + '">Directions</a></div>';
                            }

                            infoHtml += '</div>';

                            infowindow.setContent(infoHtml);
                            infowindow.open(map, marker);
                            this.map.setCenter(this.getPosition());
                        });

                        this.markers.push(marker);
                    }

                    if (this.markers.length) {
                        this.map.fitBounds(bounds);
                        if (this.markers.length === 1) {
                            this.map.setZoom(18);
                        }
                    } else {
                        var position = {
                            lat: 53.1181885,
                            lng: -1.6098285
                        };
                        this.map.setCenter(position);
                        this.map.setZoom(6.7);
                    }
                },

                filterResults() {
                    let locationInput = document.getElementById('location').value;
                    let productInput = document.getElementById('product').value;

                    if (!locationInput) {
                        this.place = null;
                        this.location = null;
                        this.locationName = null;
                    }

                    if (this.place) {
                        this.location = this.place;
                        this.locationName = this.place.address_components[0].short_name;
                    }

                    if (window.ga) {
                        window.ga('send', 'event', 'Stockist Filter', 'Click', this.location + ' - ' + productInput);
                    }

                    if (window.gtag) {
                        gtag('event', 'Click', {
                            'event_category': 'Stockist Filter',
                            'event_label': this.location + ' - ' + productInput,
                            'value': 0
                        });
                    }

                    this.stockists = [];
                    this.filterByProducts();
                    this.filterByLocation();
                    this.setMarkers();

                    // Scroll the user to the results after filtering
                    //
                    const stockistResults = document.getElementById('stockist-results')
                    const headerHeight = document.querySelector('#header').clientHeight
                    scrollTo(stockistResults, -headerHeight)

                    // Reset scroll position of stockist list
                    //
                    document.querySelector('.stockist-table .list').scrollTop = 0
                },

                filterByProducts() {
                    this.products = document.querySelector('.multiselect-products').value;

                    console.log(this.products);

                    if (this.products.length) {
                        for (var i = 0; i < this.allStockists.length; i++) {
                            var stockProducts = this.allStockists[i].products;

                            for (var j = 0; j < stockProducts.length; j++) {
                                if (this.products.indexOf(stockProducts[j]) !== -1) {
                                    this.stockists.push(this.allStockists[i]);
                                    break;
                                }
                            }
                        }
                    } else {
                        this.stockists = this.allStockists;
                    }
                },

                filterByLocation() {
                    var stockists = JSON.parse(JSON.stringify(this.stockists));

                    if (!this.place) {
                        return;
                    }

                    for (var i = 0; i < stockists.length; i++) {
                        var distance = this.calculateDistanceToLocation(stockists[i].location);
                        stockists[i].location.distance = distance;
                    }

                    stockists.sort(function (a, b) {
                        if (a.location.distance === b.location.distance) {
                            return 0;
                        }

                        return (a.location.distance < b.location.distance) ? -1 : 1;
                    });

                    for (var i = stockists.length - 1; i >= 0; --i) {
                        if (stockists[i].location.distance > this.distanceCutOff) {
                            stockists.splice(i, 1);
                        }
                    }

                    this.stockists = stockists;
                },

                onPlaceChanged() {
                    var place = this.autocomplete.getPlace();
                    if (place.geometry) {
                        this.place = place;
                    } else {
                        this.place = null;
                    }
                },

                calculateDistanceToLocation(item) {
                    var lat1 = parseFloat(this.place.geometry.location.lat());
                    var lng1 = parseFloat(this.place.geometry.location.lng());
                    var lat2 = parseFloat(item.lat);
                    var lng2 = parseFloat(item.lng);

                    if ((lat1 === lat2) && (lng1 === lng2)) {
                        return 0;
                    }

                    var radlat1 = Math.PI * lat1 / 180;
                    var radlat2 = Math.PI * lat2 / 180;

                    var theta = lng1 - lng2;
                    var radtheta = Math.PI * theta / 180;

                    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
                    if (dist > 1) {
                        dist = 1;
                    }

                    dist = Math.acos(dist);
                    dist = dist * 180 / Math.PI;
                    dist = dist * 60 * 1.1515;

                    return dist;
                }
            }
        });
    }
}
