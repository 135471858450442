//###########################################################################
// Flooring selector filter
//###########################################################################

const flooringSelectorFilter = {
    config: {
        filterContainer: '.flooring-selector-filter',
        menuBtn: '.flooring-selector-filter__menu-btn',
        results: '.flooring-selector-filter__results',
        searchForm: '.flooring-selector-search'
    },

    elements: {
        filterContainer: null,
        menuBtn: null,
        results: null,
        searchForm: null
    },

    init() {
        const self = this

        self.elements.filterContainer = document.querySelector(self.config.filterContainer)

        if (self.elements.filterContainer !== null) {
            self.elements.menuBtn = self.elements.filterContainer.querySelector(self.config.menuBtn)
            self.elements.results = self.elements.filterContainer.querySelector(self.config.results)

            self.handleMenuBtn()
        }

        self.elements.searchForm = document.querySelector(self.config.searchForm)

        if (self.elements.searchForm !== null) {
            self.handleSearchForm()
        }
    },

    handleMenuBtn() {
        const self = this

        self.elements.menuBtn.addEventListener('click', function (e) {
            e.preventDefault()

            if (self.elements.filterContainer.classList.contains('menu-open')) {
                self.elements.filterContainer.classList.remove('menu-open')
            } else {
                self.elements.filterContainer.classList.add('menu-open')
            }
        })
    },

    handleSearchForm() {
        const self = this

        let filterParams = '?'

        let selects = self.elements.searchForm.querySelectorAll('select')

        self.elements.searchForm.addEventListener('submit', function(e) {
            e.preventDefault()

            let firstWithValue = false

            for (let i = 0; i < selects.length; i++) {
                const select = selects[i]

                if (select.value !== '') {
                    if (firstWithValue === false) {
                        filterParams = "?" + select.value

                        firstWithValue = true
                    } else {
                        filterParams = filterParams + "&" + select.value
                    }
                }
            }

            if (filterParams !== '?') {
                window.location.href = "/flooring-selector/results" + filterParams
            } else {
                window.location.href = "/flooring-selector/results"
            }
        })
    }
}

window.addEventListener('load', function () {
    flooringSelectorFilter.init()
})