//###########################################################################
// Contact form
//###########################################################################

import Vue from 'vue'

Vue.config.productionTip = false
import {VueRecaptcha} from 'vue-recaptcha'
import {scrollTo} from '../utilities/_scrollTo'

if (document.querySelector('#form-contact')) {
    new Vue({
        el: '#form-contact',
        delimiters: ['${', '}'],
        data: {
            errors: [],
            subject: '',
            fromName: null,
            fromEmail: null,
            enquiryType: null,
            facadesOptions: null,
            timberOptions: null,
            howCanWeHelp: null,
            companyName: null,
            companyAddress: null,
            companyPostcode: null,
            phoneNumber: null,
            message: null,
            privacyConfirmation: null,
            website: '',
            recaptchaVerified: false
        },
        components: {VueRecaptcha},
        methods: {
            send: function (e) {
                e.preventDefault()

                this.errors = []

                if (!this.enquiryType) {
                    this.errors.push("Please select an enquiry type.")
                }

                if (!this.howCanWeHelp) {
                    this.errors.push("Please select a 'How can we help?' option.")
                }

                if (!this.fromName) {
                    this.errors.push("Name required.")
                }

                if (!this.fromEmail) {
                    this.errors.push('Email required.')
                }

                if (this.fromEmail && !this.validateEmail(this.fromEmail)) {
                    this.errors.push('Email address is incorrect.')
                }

                if (!this.phoneNumber) {
                    this.errors.push('Phone number is required.')
                }

                if (!this.companyName) {
                    this.errors.push('Company name is required.')
                }

                if (!this.companyAddress) {
                    this.errors.push('Company address is required.')
                }

                if (!this.companyPostcode) {
                    this.errors.push('Company postcode is required.')
                }

                if (!this.message) {
                    this.errors.push('Additional notes is required.')
                }

                if (!this.privacyConfirmation) {
                    this.errors.push('Please confirm that you have read the Privacy Notice.')
                }

                if (!this.recaptchaVerified) {
                    this.errors.push('Please complete the Google Recaptcha.')
                }

                if (this.website !== '') {
                    this.errors.push('An unknown error occured.')
                }

                if (this.errors.length > 0) {
                    // If there's errors, scroll to them and offset by header (because we're scrolling up)
                    const headerHeight = document.querySelector('#header').clientHeight
                    scrollTo(document.querySelector('#form-contact'), -headerHeight)
                }

                if (!this.errors.length) {
                    this.errors = []
                    document.querySelector('#form-contact').classList.add("form--submitting")

                    if (window.ga) {
                        if (document.querySelector('#fileAttachment').value) {
                            window.ga('send', 'event', 'Form', 'Submission', 'Contact Page w/ Files (Enquiry Type: '+ this.enquiryType +') (How can we help: '+ this.howCanWeHelp +')');
                        } else {
                            window.ga('send', 'event', 'Form', 'Submission', 'Contact Page (Enquiry Type: '+ this.enquiryType +') (How can we help: '+ this.howCanWeHelp +')');
                        }
                    }

                    if (window.gtag) {
                        if (document.querySelector('#fileAttachment').value) {
                            gtag('event', 'Submission', {
                                'event_category': 'Form',
                                'event_label': 'Contact Page w/ Files (Enquiry Type: '+ this.enquiryType +') (How can we help: '+ this.howCanWeHelp +')',
                                'value': 0
                            });
                        } else {
                            gtag('event', 'Submission', {
                                'event_category': 'Form',
                                'event_label': 'Contact Page (Enquiry Type: '+ this.enquiryType +') (How can we help: '+ this.howCanWeHelp +')',
                                'value': 0
                            });
                        }
                    }

                    e.target.submit()
                }
            },

            handleEnquiryTypeSelect(event) {
                if (event.target.value == 'Facades') {
                    document.querySelector('#howCanWeHelp').innerHTML = ''
                    this.addHowCanWeHelpDefaultOption()

                    for (let option in this.facadesOptions) {
                        const optionObj = this.facadesOptions[option]
                        this.addHowCanWeHelpOption(optionObj.title, optionObj.title)
                    }
                } else if (event.target.value == 'Timber') {
                    document.querySelector('#howCanWeHelp').innerHTML = ''
                    this.addHowCanWeHelpDefaultOption()

                    for (let option in this.timberOptions) {
                        const optionObj = this.timberOptions[option]
                        this.addHowCanWeHelpOption(optionObj.title, optionObj.title)
                    }
                }
            },

            addHowCanWeHelpDefaultOption() {
                const select = document.querySelector('#howCanWeHelp')

                const option = document.createElement("option")
                option.text = "Select help required"
                option.value = ""
                option.disabled = true
                select.appendChild(option)

                setTimeout(function () {
                    select.options.selectedIndex = 0
                }, 250)
            },

            addHowCanWeHelpOption(text, value) {
                const self = this

                const select = document.querySelector('#howCanWeHelp')
                const option = document.createElement("option")

                if (text !== false) {
                    option.text = text
                }

                if (value !== false) {
                    option.value = value
                }

                select.appendChild(option)
            },

            validateEmail(email) {
                const filter = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                return filter.test(email)
            },

            verifyRecaptcha() {
                this.recaptchaVerified = true
            },
        },

        mounted() {
            // Initially clear form
            //
            document.querySelector('#form-contact form').reset()

            // Get options for enquiry type
            //
            this.facadesOptions = JSON.parse(document.querySelector('#facadesOptions').getAttribute('value'))
            this.timberOptions = JSON.parse(document.querySelector('#timberOptions').getAttribute('value'))
        }
    })
}