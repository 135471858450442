//###########################################################################
// Add element
//###########################################################################

export const addElement = (options) => {
    // Create a new element
    //
    const newElement = document.createElement(options.elementTag)

    // Handle 'this' context
    const self = options.this

    // If classes are set then add each one
    //
    if ( options.classes !== '' && options.classes !== undefined ) {
        const classesArray = options.classes.split(" ")

        for (let i = 0; i < classesArray.length; i++ ) {
            newElement.classList.add(classesArray[i])
        }
    }

    // If attributes are set then add each one
    //
    if ( options.setAttributes !== '' && options.setAttributes !== undefined ) {
        for (let attribute in options.setAttributes) {
            const value = options.setAttributes[attribute]
            newElement.setAttribute(attribute, value)
        }
    }

    // Insert innerHTML if set
    //
    if ( options.innerHTML !== '' && options.innerHTML !== undefined ) {
        newElement.innerHTML = options.innerHTML
    }

    // Get the parent node
    //
    let parentElement = null
    if ( options.appendTo instanceof Element || options.appendTo instanceof HTMLDocument ) {
        parentElement = options.appendTo
    } else if ( typeof options.appendTo == 'string' ) {
        parentElement = document.querySelector(options.appendTo)
    }

    // Insert the new node
    //
    parentElement.append(newElement)

    // If after function is set then handle function call
    //
    if ( options.after !== undefined ) {
        options.after(newElement, parentElement, self)
    }
}

// Use example:
//
/*
addElement({
  elementTag: 'section',
  classes: 'section--a section--large',
  setAttributes: {
    'id': 'section-contact',
    'data-name': 'contact'
  },
  innerHTML: '<p>Paragraph</p>',
  appendTo: window.app.main,
  after: () => {
    console.log('el added')
  }
})
*/
