//###########################################################################
// Resources
//###########################################################################

import Vue from 'vue'

Vue.config.productionTip = false
import axios from "axios"
import {scrollTo} from "../utilities/_scrollTo"

if (document.querySelector('.block-resources')) {
    new Vue({
        el: '.block-resources',
        delimiters: ['${', '}'],
        data: {
            productFilterIds: [],
            typeFilter: [],
            typeFilterClasses: [],
            showResourcesAll: true,
            allIsEmpty: false,
            filteredIsEmpty: false
        },

        beforeMount() {
            const self = this

            let url = new URL(window.location.href)

            if (url.searchParams.has('product') && url.searchParams.get('product') !== '' || url.searchParams.has('type') && url.searchParams.get('type') !== '') {
                setTimeout(function() {
                    const allCheckboxes = document.querySelectorAll('input[type="checkbox"]:checked')

                    for (let i = 0; i < allCheckboxes.length; i++) {
                        const checkbox = allCheckboxes[i]

                        if ("createEvent" in document) {
                            var evt = document.createEvent("HTMLEvents")
                            evt.initEvent("change", false, true)
                            checkbox.dispatchEvent(evt)
                        } else {
                            checkbox.fireEvent("onchange")
                        }

                        const parentGroup = checkbox.closest('.resources-menu__group')

                        if (parentGroup !== null) {
                            parentGroup.classList.add('open')
                        }
                    }
                }, 200)
            }
        },

        mounted() {
            this.initTypeFilters()

            // Set initial typeFilterClasses
            //
            const typeFilters = document.querySelectorAll('.resources-menu__option--type')
            for (let i = 0; i < typeFilters.length; i++) {
                const typeFilterInputId = typeFilters[i].querySelector('input').id
                this.typeFilterClasses.push('.' + typeFilterInputId)
            }
        },

        methods: {
            handleResourceOptionTitle(event) {
                const labelToClick = event.target.parentElement.querySelector('.checkbox label')
                labelToClick.click()
            },

            handleResourceGroupTrigger(event) {
                const parentGroup = event.target.closest('.resources-menu__group')

                if (parentGroup.classList.contains('open')) {
                    parentGroup.classList.remove('open')
                } else {
                    parentGroup.classList.add('open')
                }
            },

            handleProductFilter(event) {
                const productId = event.target.getAttribute('data-product-id')
                const type = document.querySelector('.block-resources').getAttribute('data-type')

                if (window.ga) {
                    let gaLabel =
                        event.target.getAttribute('data-product-type')
                        + ' - '
                        + event.target.getAttribute('data-product-category-title')
                        + ' - '
                        + event.target.getAttribute('data-product-title');

                    window.ga('send', 'event', 'Resource Filter - Product', 'Click', gaLabel);
                }

                if (window.gtag) {

                    let gaLabel =
                        event.target.getAttribute('data-product-type')
                        + ' - '
                        + event.target.getAttribute('data-product-category-title')
                        + ' - '
                        + event.target.getAttribute('data-product-title');

                    gtag('event', 'Click', {
                        'event_category': 'Resource Filter - Product',
                        'event_label': gaLabel,
                        'value': 0
                    });
                }

                if (this.productFilterIds.includes(productId)) {
                    this.productFilterIds = this.productFilterIds.filter(function (item) {
                        return item !== productId
                    })
                } else {
                    this.productFilterIds.push(productId)
                }

                // Set loading states
                //
                document.querySelector('.resources-all').classList.add('loading')
                document.querySelector('.resources-filtered').classList.add('loading')

                axios.get('get-resources?ids=' + this.productFilterIds.toString() + '&type=' + type)
                    .then(res => {
                        const resourcesFiltered = document.querySelector('.resources-filtered')

                        resourcesFiltered.innerHTML = res.data

                        // If on change, no type filters are set, remove all filtered classes
                        //
                        let noneSelected = true
                        const typeFilters = document.querySelectorAll('.resources-menu__option--type')
                        for (let i = 0; i < typeFilters.length; i++) {
                            const typeFilter = typeFilters[i]
                            const typeFilterInput = typeFilter.querySelector('input')

                            if (typeFilterInput.checked) {
                                noneSelected = false
                            }
                        }

                        if (noneSelected === true) {
                            this.resetTypeFilterClasses()
                        } else {
                            this.initTypeFilters()
                        }

                        this.reInitGoogleAnalyticsTracking();

                        // Remove loading states
                        //
                        document.querySelector('.resources-all').classList.remove('loading')
                        document.querySelector('.resources-filtered').classList.remove('loading')
                    })
                    .catch(err => {
                        console.log(err)
                    })

                // Check product filters
                //
                this.checkProductFilters()

                // Scroll to results
                //
                const headerHeight = document.querySelector('#header').clientHeight
                scrollTo(document.querySelector('#resources-container'), -headerHeight)

                // Update query string
                //
                this.updateQueryStringUrl()
            },

            checkProductFilters() {
                const productFilters = document.querySelectorAll('.resources-menu__option--product')
                this.showResourcesAll = true

                for (let i = 0; i < productFilters.length; i++) {
                    const productFilter = productFilters[i]

                    const productFilterInput = productFilter.querySelector('input')

                    if (productFilterInput.checked) {
                        this.showResourcesAll = false

                        document.querySelector('.resources-filtered').innerHTML = ''
                    }
                }
            },

            initTypeFilters() {
                const self = this

                const typeFilters = document.querySelectorAll('.resources-menu__option--type')

                let noneSelected = true
                for (let i = 0; i < typeFilters.length; i++) {
                    const typeFilter = typeFilters[i]

                    const typeFilterInput = typeFilter.querySelector('input')
                    const typeFilterInputId = typeFilterInput.id

                    if (typeFilterInput.checked) {
                        noneSelected = false
                    }
                    // this.typeFilterClasses.push('.' + typeFilterInputId)
                }

                if (!noneSelected) {
                    // Remove all hidden classes before showing newly filtered items
                    //
                    this.resetTypeFilterClasses()

                    // For each type filter class, hide
                    //
                    for (let i = 0; i < this.typeFilterClasses.length; i++) {
                        const documentListItems = document.querySelectorAll(this.typeFilterClasses[i])

                        for (let k = 0; k < documentListItems.length; k++) {
                            documentListItems[k].classList.add('hidden')
                        }
                    }

                    setTimeout(function () {
                        // If block is empty, hide block
                        //
                        self.checkIfBlocksAreEmpty()
                    }, 100)
                }
            },

            handleTypeFilter(event) {
                const self = this
                const classNameToFilter = '.' + event.target.id

                if (this.typeFilterClasses.includes(classNameToFilter)) {
                    self.typeFilterClasses = self.typeFilterClasses.filter(function (item) {
                        return item !== classNameToFilter
                    })

                    let gaLabel =
                        event.target.getAttribute('data-product-type')
                        + ' - '
                        + event.target.getAttribute('data-type-title');

                    if (window.ga) {
                        window.ga('send', 'event', 'Resource Filter - Type', 'Click', gaLabel);
                    }

                    if (window.gtag) {
                        gtag('event', 'Click', {
                            'event_category': 'Resource Filter - Type',
                            'event_label': gaLabel,
                            'value': 0
                        });
                    }

                } else {
                    this.typeFilterClasses.push(classNameToFilter)
                }

                if (this.typeFilter.includes(event.target.id)) {
                    this.typeFilter = this.typeFilter.filter(function (item) {
                        return item !== event.target.id
                    })
                } else {
                    this.typeFilter.push(event.target.id)
                }

                // If on change, no type filters are set, remove all filtered classes
                //
                setTimeout(function () {
                    let noneSelected = true
                    const typeFilters = document.querySelectorAll('.resources-menu__option--type')
                    for (let i = 0; i < typeFilters.length; i++) {
                        const typeFilter = typeFilters[i]
                        const typeFilterInput = typeFilter.querySelector('input')

                        if (typeFilterInput.checked) {
                            noneSelected = false
                        }
                    }

                    if (noneSelected) {
                        // Remove all hidden classes
                        //
                        self.resetTypeFilterClasses()

                        setTimeout(function () {
                            // If block is empty, hide block
                            //
                            self.checkIfBlocksAreEmpty()
                        }, 100)
                    } else {
                        // Remove all hidden classes before showing newly filtered items
                        //
                        self.resetTypeFilterClasses()

                        // For each type filter class, hide
                        //
                        for (let i = 0; i < self.typeFilterClasses.length; i++) {
                            const documentListItems = document.querySelectorAll(self.typeFilterClasses[i])

                            for (let k = 0; k < documentListItems.length; k++) {
                                documentListItems[k].classList.add('hidden')
                            }
                        }

                        setTimeout(function () {
                            // If block is empty, hide block
                            //
                            self.checkIfBlocksAreEmpty()
                        }, 100)
                    }
                }, 100)

                // Scroll to results
                //
                const headerHeight = document.querySelector('#header').clientHeight
                scrollTo(document.querySelector('#resources-container'), -headerHeight)

                // Update query string
                //
                this.updateQueryStringUrl()
            },

            resetTypeFilterClasses() {
                const documentListItems = document.querySelectorAll('.resources-document')

                for (let i = 0; i < documentListItems.length; i++) {
                    const documentListItem = documentListItems[i]

                    documentListItem.classList.remove('hidden')
                }
            },

            checkIfBlocksAreEmpty() {
                const self = this

                const resourcesAll = document.querySelector('.resources-all')
                const resourcesFiltered = document.querySelector('.resources-filtered')

                const resourcesAllBlocks = resourcesAll.querySelectorAll('.tile-resource-block')
                let allEmpty = true
                for (let i = 0; i < resourcesAllBlocks.length; i++) {
                    const resourceBlockListItems = resourcesAllBlocks[i].querySelectorAll('li')

                    let isEmpty = true

                    for (let k = 0; k < resourceBlockListItems.length; k++) {
                        if (!resourceBlockListItems[k].classList.contains('hidden')) {
                            isEmpty = false
                            allEmpty = false
                        }
                    }

                    if (isEmpty) {
                        resourcesAllBlocks[i].classList.add('hidden')
                    } else {
                        resourcesAllBlocks[i].classList.remove('hidden')
                    }
                }

                if (allEmpty === true) {
                    this.allIsEmpty = true
                } else {
                    this.allIsEmpty = false
                }

                const resourcesFilteredBlocks = resourcesFiltered.querySelectorAll('.tile-resource-block')
                let filteredEmpty = true
                for (let i = 0; i < resourcesFilteredBlocks.length; i++) {
                    const resourceBlockListItems = resourcesFilteredBlocks[i].querySelectorAll('li')

                    let isEmpty = true

                    for (let k = 0; k < resourceBlockListItems.length; k++) {
                        if (!resourceBlockListItems[k].classList.contains('hidden')) {
                            isEmpty = false
                            filteredEmpty = false
                        }
                    }

                    if (isEmpty) {
                        resourcesFilteredBlocks[i].classList.add('hidden')
                    } else {
                        resourcesFilteredBlocks[i].classList.remove('hidden')
                    }
                }

                if (filteredEmpty === true) {
                    this.filteredIsEmpty = true
                } else {
                    this.filteredIsEmpty = false
                }
            },

            reInitGoogleAnalyticsTracking() {
                // Generic outbound links
                const filetypes = /\.(zip|exe|dmg|pdf|doc.*|docx|xls.*|ppt.*|mp3|txt|rar|wma|mov|avi|wmv|flv|wav)$/i;
                let baseHref = window.location.href;
                let links = document.querySelectorAll('a');
                for (let i = 0; i < links.length; i++) {
                    links[i].addEventListener("click", (e) => {

                        let el = links[i];
                        let track = true;
                        let href = (typeof (el.getAttribute('href')) != 'undefined') ? el.getAttribute('href') : "";

                        let isThisDomain = href.match(document.domain.split('.').reverse()[1] + '.' + document.domain.split('.').reverse()[0]);

                        let elEv = [];

                        elEv.value = 0;
                        elEv.non_i = false;

                        if (href.match(/^mailto\:/i)) {
                            elEv.category = "Mail";
                            elEv.action = "Click";
                            elEv.label = href.replace(/^mailto\:/i, '');
                            elEv.loc = href;

                        } else if (href.match(filetypes)) {

                            let extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : undefined;

                            elEv.category = "Downloads";
                            elEv.action = "Click";

                            elEv.label = extension[0].toUpperCase();

                            if (links[i].getAttribute('data-filename')) {
                                elEv.label = $(this).data('filename');
                            } else {
                                elEv.label = href.replace(/ /g, "-");
                            }

                            elEv.loc = baseHref + href;

                        } else if (href.match(/^https?\:/i) && !isThisDomain) {
                            elEv.category = "Outbound links";
                            elEv.action = "Click";
                            elEv.label = href.replace(/^https?\:\/\//i, '');
                            elEv.non_i = true;
                            elEv.loc = href;

                        } else if (href.match(/^tel\:/i)) {
                            elEv.category = "Telephone";
                            elEv.action = "Click";
                            elEv.label = href.replace(/^tel\:/i, '');
                            elEv.loc = href;

                        } else {
                            track = false;
                        }

                        if (track) {
                            console.log("Category: " + elEv.category + " | " + "Action: " + elEv.action + " | " + "Label: " + elEv.label + " | " + "Value: " + elEv.value);

                            if (window.ga) {
                                window.ga('send', 'event', elEv.category, elEv.action, elEv.label, elEv.value);
                            }

                            if (window.gtag) {
                                gtag('event', elEv.action, {
                                    'event_category': elEv.category,
                                    'event_label': elEv.label,
                                    'value': elEv.value
                                });
                            }

                            if (el.getAttribute('target') == undefined || el.getAttribute('target').toLowerCase() != '_blank') {
                                setTimeout(function () {
                                    location.href = elEv.loc;
                                }, 400);
                                return false;
                            }
                        }
                    });
                }
            },

            updateQueryStringUrl() {

                let productFilterIdsString = "";
                for (let i = 0; i < this.productFilterIds.length; i++) {
                    productFilterIdsString += this.productFilterIds[i] + ','
                }

                let typeFilterIdsString = "";
                for (let i = 0; i < this.typeFilter.length; i++) {
                    typeFilterIdsString += this.typeFilter[i] + ','
                }

                let url = window.location.href;
                url = this.updateUrlParams(url, {product: productFilterIdsString, type: typeFilterIdsString});
                window.history.pushState({}, '', url);
            },

            updateUrlParams(url, params) {
                const urlObject = new URL(url);
                for (const [key, value] of Object.entries(params)) {
                    urlObject.searchParams.set(key, value);
                }
                return urlObject.href;
            }
        }
    })
}