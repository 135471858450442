//###########################################################################
// Product selector filter
//###########################################################################

const productSelectorFilter = {
    config: {
        productSelect: '.product-selector-filter #swatch-product',
        colourSelect: '.product-selector-filter #swatch-colour',
        textureSelect: '.product-selector-filter #swatch-texture',
        finishSelect: '.product-selector-filter #swatch-finish',
        colourRangeSelect: '.product-selector-filter #swatch-colour-range',
    },

    elements: {
        productSelect: null,
        colourSelect: null,
        textureSelect: null,
        finishSelect: null,
        colourRangeSelect: null
    },

    state: {
        product: 's=',
        colour: 'c=',
        texture: 't=',
        finish: 'f=',
        colourRange: 'cr=',
    },

    init() {
        const self = this

        self.elements.productSelect = document.querySelector(self.config.productSelect)
        self.elements.colourSelect = document.querySelector(self.config.colourSelect)
        self.elements.textureSelect = document.querySelector(self.config.textureSelect)
        self.elements.finishSelect = document.querySelector(self.config.finishSelect)
        self.elements.colourRangeSelect = document.querySelector(self.config.colourRangeSelect)

        self.handleSelects()
    },

    handleSelects() {
        const self = this

        if (self.elements.productSelect !== null) {
            self.state.product = self.elements.productSelect.value

            self.elements.productSelect.addEventListener('change', function () {
                self.state.product = self.elements.productSelect.value

                window.location = self.getUrl()

                return false
            })
        }

        if (self.elements.colourSelect !== null) {
            self.state.colour = self.elements.colourSelect.value

            self.elements.colourSelect.addEventListener('change', function () {
                self.state.colour = self.elements.colourSelect.value

                window.location = self.getUrl()

                return false
            })
        }

        if (self.elements.textureSelect !== null) {
            self.state.texture = self.elements.textureSelect.value

            self.elements.textureSelect.addEventListener('change', function () {
                self.state.texture = self.elements.textureSelect.value

                window.location = self.getUrl()

                return false
            })
        }

        if (self.elements.finishSelect !== null) {
            self.state.finish = self.elements.finishSelect.value

            self.elements.finishSelect.addEventListener('change', function () {
                self.state.finish = self.elements.finishSelect.value

                window.location = self.getUrl()

                return false
            })
        }

        if (self.elements.colourRangeSelect !== null) {
            self.state.colourRange = self.elements.colourRangeSelect.value

            self.elements.colourRangeSelect.addEventListener('change', function () {
                self.state.colourRange = self.elements.colourRangeSelect.value

                window.location = self.getUrl()

                return false
            })
        }
    },

    getUrl() {
        const self = this

        return '?' + self.state.product + '&' + self.state.colour + '&' + self.state.texture + '&' + self.state.finish + '&' + self.state.colourRange
    }
}

window.addEventListener('load', function () {
    productSelectorFilter.init()
})