//###########################################################################
// Careers apply
//###########################################################################

import Vue from 'vue'
Vue.config.productionTip = false
import { scrollTo } from '../utilities/_scrollTo'
import { VueRecaptcha } from 'vue-recaptcha'

if (document.querySelector('#form-careers')) {
    new Vue({
        el: '#form-careers',
        delimiters: ['${', '}'],
        data: {
            errors: [],
            subject: '',
            fromName: null,
            fromEmail: null,
            phoneNumber: null,
            role: null,
            whereDidYouHear: null,
            coverLetter: null,
            cv: null,
            message: null,
            privacyConfirmation: null,
            recaptchaVerified: false,
            website: ''
        },
        components: { VueRecaptcha },
        methods: {
            send: function(e) {
                e.preventDefault()

                this.errors = []

                if (!this.fromName) {
                    this.errors.push("Name required.")
                }

                if (!this.fromEmail) {
                    this.errors.push('Email required.')
                }

                if (this.fromEmail && !this.validateEmail(this.fromEmail)) {
                    this.errors.push('Email address is incorrect.')
                }

                if (!this.phoneNumber) {
                    this.errors.push('Phone number is required.')
                }

                if (!this.hasFile(document.querySelector('#coverLetter'))) {
                    this.errors.push('Cover letter is required.')
                }

                if (!this.hasFile(document.querySelector('#cv'))) {
                    this.errors.push('CV is required.')
                }

                if (!this.message) {
                    this.errors.push('Please enter a message.')
                }

                if (!this.privacyConfirmation) {
                    this.errors.push('Please confirm that you have read the Job Applicant Privacy Notice.')
                }

                if (!this.recaptchaVerified) {
                    this.errors.push('Please complete the Google Recaptcha.')
                }

                if (this.website !== '') {
                    this.errors.push('An unknown error occured.')
                }

                if (this.errors.length > 0) {
                    // If there's errors, scroll to them and offset by header (because we're scrolling up)
                    const headerHeight = document.querySelector('#header').clientHeight
                    scrollTo(document.querySelector('#form-careers'), -headerHeight)
                }

                if (!this.errors.length) {
                    this.errors = []
                    document.querySelector('#form-careers').classList.add("form--submitting")

                    if (window.ga) {
                        window.ga('send', 'event', 'Form', 'Submission', 'Careers Page');
                    }

                    if (window.gtag) {
                        gtag('event', 'Submission', {
                            'event_category': 'Form',
                            'event_label': 'Careers Page',
                            'value': 0
                        });
                    }

                    e.target.submit()
                }
            },

            validateEmail(email) {
                const filter = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                return filter.test(email)
            },

            hasFile(input) {
                return input.files.length !== 0;
            },

            verifyRecaptcha() {
                this.recaptchaVerified = true
            },
        },

        mounted() {
            // Initially clear form
            //
            document.querySelector('#form-careers form').reset()

            // Ensure selected option is set based on slug
            //
            for (let i = 0; i < document.querySelector('#role').options.length; i++) {
                const option = document.querySelector('#role').options[i]

                if (document.querySelector('#role').getAttribute('data-page-slug') === option.value) {
                    document.querySelector('#role').value = option.value
                }
            }

            this.role = document.querySelector('#role').value
            this.whereDidYouHear = document.querySelector('#whereDidYouHear').value
        }
    })
}
